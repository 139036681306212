import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import {
    Box, AppBar, Toolbar, Typography, Button,
    Card, CircularProgress, Divider, Avatar,
    Dialog, DialogTitle, DialogContent, DialogActions,
} from '@mui/material';


import { detailViewSelector, rackSelector, getMileage } from '../redux/assets/assetsSlice';
import { theme } from '../theme';
import {
    periodList, periodNameList, autoLoadPeriods,
    lastEventTimeRange, getPeriodRange
} from '../utils/datePeriod';

import Icon from '@mdi/react';
import {
    mdiRoadVariant, mdiChartLine,
    mdiGasStation, mdiBarrel, mdiArrowRightThin,
    mdiHydraulicOilLevel, mdiCarCoolantLevel,
    mdiNull
} from '@mdi/js';


const ActionSummary = () => {

    const colors = theme.palette;
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const rack = useSelector(rackSelector);
    const detailView = useSelector(detailViewSelector);
    const asset = rack[detailView];

    useEffect(() => {
        if (!asset) {
            navigate('/');
        }
    }, [navigate, asset]);

    const lastEventTime = asset && asset.lastEvent && moment.unix(asset.lastEvent.event_end_time).subtract(330, 'minutes').format('YYYY-MM-DD HH:mm:ss');

    const loadData = useMemo(() => {
        return (period) => {
            if (asset && asset.action[period].status === 'loading') return;
            if (asset) {
                if (asset.action[period]) {
                    if (asset.action[period].status === 'loaded') {
                        if (asset.action[period].updated) {
                            const lastUpdated = moment(asset.action[period].updated);
                            const now = moment();
                            const diff = now.diff(lastUpdated, 'minutes');
                            if (diff < 5) {
                                return;
                            };
                        }
                    }
                }
            }
            const periodName = periodNameList[period];
            if (period === 'sinceLastFilling' && lastEventTime) {
                const { timeFrom, timeTo } = lastEventTimeRange(lastEventTime);
                dispatch(getMileage({ imei: detailView, period, periodName, timeFrom, timeTo }));
            } else {
                const { timeFrom, timeTo } = getPeriodRange(period);
                dispatch(getMileage({ imei: detailView, period, periodName, timeFrom, timeTo }));
            }

        };
    }, [dispatch, detailView, lastEventTime]);

    useEffect(() => {
        autoLoadPeriods.forEach((period) => {
            loadData(period);
        });
    }, [loadData]);


    const [open, setOpen] = useState(false);
    const [selectedData, setSelectedData] = useState(null);
    const [thePeriod, setThePeriod] = useState('');
    const handleOpenDialog = (periodItem) => {
        const data = asset && asset.action[periodItem];
        if (data) {
            setSelectedData(data);
            setThePeriod(periodNameList[periodItem]);
            setOpen(true);
        }
    };

    const handleCloseDialog = () => {
        setOpen(false);
        setSelectedData(null);
        setThePeriod('');
    };

    const mileageDisplay = (periodItem, index) => {
        const periodName = periodNameList[periodItem];
        const data = asset && asset.action[periodItem];
        const { timeFrom, timeTo } = getPeriodRange(periodItem);
        if (!data) return null;
        return (
            <Card sx={{ width: '100%', padding: 1, borderRadius: 2, boxShadow: 2, backgroundColor: colors.gainsboro.shade2, marginBottom: '0.5rem' }} key={index}>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', }}>
                    <Box sx={{
                        padding: 1,
                        backgroundColor: colors.gainsboro.shade1,
                        borderRadius: 2,
                        marginBottom: 1,
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}>

                        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>
                            <Typography variant="h5" sx={{ color: colors.text.main }}><b>{periodName}</b></Typography>
                            {
                                data.status !== null && data.startTime &&
                                <Typography variant="body1" sx={{ color: colors.text.lighter, fontSize: '0.6rem' }}>
                                    {
                                        periodItem === 'sinceLastFilling' ?
                                            moment(data.startTime).format('D-MMM hh:mm A')
                                            :
                                                periodItem === 'today' ?
                                                    moment(timeFrom).format('h:mm A')
                                                    :
                                                    moment(timeFrom).format('D-MMM')
                                    }
                                    {
                                            periodItem === 'yesterday' ?
                                                ''
                                                : periodItem === 'sinceLastFilling' || periodItem === 'today' || periodItem === 'thisWeek' || periodItem === 'thisMonth' ?
                                                    '- Now'
                                                    : ` - ${moment(timeTo).format('D-MMM')}`

                                    }

                                </Typography>
                            }
                        </Box>
                        {
                            data.status === null ?
                                <Typography
                                    variant="body1"
                                    color={colors.text.primary}
                                    sx={{ marginRight: '5px', marginLeft: '5px' }}
                                    onClick={() => loadData(periodItem)}
                                >
                                    <span style={{ color: colors.primary.shade11, cursor: 'pointer', textDecoration: 'underline' }}>
                                        Load
                                    </span>
                                </Typography>
                                :
                                data.status === 'loading' ?
                                    <CircularProgress size={20} color="primary" />
                                    :
                                    data.status === 'loaded' && moment().diff(data.updated, 'minutes') > 5 ?
                                        <Typography
                                            variant="body1"
                                            color={colors.text.primary}
                                            sx={{ marginRight: '5px', marginLeft: '5px' }}
                                            onClick={() => loadData(periodItem)}
                                        >
                                            <span style={{ color: colors.primary.shade11, cursor: 'pointer', textDecoration: 'underline' }}>
                                                Refresh
                                            </span>
                                        </Typography>
                                        :
                                        null
                        }

                    </Box>
                    <Divider sx={{ color: colors.grey.main }} />
                    <br />
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                            <Avatar sx={{ bgcolor: colors.freenzomp.shade1 }}>
                                <Icon path={mdiRoadVariant} size={1} color={colors.grey.shade15} />
                            </Avatar>
                            <Typography variant="body1" sx={{ color: colors.text.lighter, fontWeight: 'bold', ml: 2 }}>Distance</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography variant="h5" sx={{ color: colors.freenzomp.main }}>
                                {data.distance && `${data.distance} Km`}
                            </Typography>
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                            <Avatar sx={{ bgcolor: colors.redfire.shade1 }}>
                                <Icon path={mdiHydraulicOilLevel} size={1} color={colors.redfire.shade12} />
                            </Avatar>
                            <Typography variant="body1" sx={{ color: colors.text.lighter, fontWeight: 'bold', ml: 2 }}>Consumption</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                            {
                                data.consumption ?
                                    data.consumption !== 'NA' ?
                                    <Typography variant="h5" sx={{ color: colors.redfire.main }}>
                                        {data.consumption && `${data.consumption} L`}
                                    </Typography>
                                    :
                                        <Icon path={mdiNull} size={1} color={colors.grey.shade6} />
                                    : null
                            }

                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                            <Avatar sx={{ bgcolor: colors.secondary.shade1 }}>
                                <Icon path={mdiChartLine} size={1} color={colors.secondary.shade12} />
                            </Avatar>
                            <Typography variant="body1" sx={{ color: colors.text.lighter, fontWeight: 'bold', ml: 2 }}>Mileage</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                            {
                                data.mileage ?
                                    data.mileage !== 'NA' ?
                                    <Typography variant="h5" sx={{ color: colors.secondary.main }}>
                                        {data.mileage && `${data.mileage} Km/L`}
                                    </Typography>
                                    :
                                        <Icon path={mdiNull} size={1} color={colors.grey.shade6} />
                                    : null
                            }
                        </Box>
                    </Box>
                    {
                        data.startLevel && data.endLevel && data.consumption > 20 && periodItem !== 'sinceLastFilling' &&
                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                                <Avatar sx={{ bgcolor: colors.yellow.shade1 }}>
                                    <Icon path={mdiCarCoolantLevel} size={1} color={colors.yellow.shade12} />
                                </Avatar>
                                <Typography variant="body1" sx={{ color: colors.text.lighter, fontWeight: 'bold', ml: 2 }}>Tank Level</Typography>
                            </Box>

                            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                                    <Typography variant="h5" sx={{ color: colors.yellow.shade13 }}>
                                        {
                                            `${parseFloat(data.startLevel).toFixed(0)}L`
                                        }
                                </Typography>
                                <Icon path={mdiArrowRightThin} size={1} color={colors.grey.shade8} />
                                    <Typography variant="h5" sx={{ color: colors.yellow.shade13 }}>
                                    {`${parseFloat(data.endLevel).toFixed(0)}L`}
                                </Typography>
                            </Box>
                        </Box>
                    }
                    {
                        periodItem !== 'sinceLastFilling' && data.fillCount > 0 &&
                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }} onClick={() => handleOpenDialog(periodItem)} >
                            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                                <Avatar sx={{ bgcolor: colors.primary.shade1 }}>
                                    <Icon path={mdiGasStation} size={1} color={colors.primary.shade12} />
                                    </Avatar>
                                    <Typography variant="body1" sx={{ color: colors.text.lighter, fontWeight: 'bold', ml: 2 }}>Filling</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Typography variant="h5" sx={{ color: colors.primary.main }}>
                                    {parseFloat(data.totalFills).toFixed(0)} L | {data.fillCount === 1 ? 'Once' : `${data.fillCount} times`}
                                </Typography>
                            </Box>
                        </Box>
                    }
                    {
                        periodItem !== 'sinceLastFilling' && data.theftCount > 0 &&
                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }} onClick={() => handleOpenDialog(periodItem)}>
                            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                                <Avatar sx={{ bgcolor: colors.redfire.shade1 }}>
                                    <Icon path={mdiBarrel} size={1} color={colors.redfire.shade12} />
                                </Avatar>
                                <Typography variant="body1" sx={{ color: colors.text.lighter, fontWeight: 'bold', ml: 2 }}>Theft</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Typography variant="h5" sx={{ color: colors.redfire.main }}>
                                    {parseFloat(data.totalTheft).toFixed(0)} L | {data.theftCount === 1 ? 'Once' : `${data.theftCount} times`}
                                </Typography>
                            </Box>
                        </Box>
                    }
                </Box>
            </Card>
        );
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh', width: '100%' }}>
            <AppBar position="fixed">
                <Toolbar sx={{ backgroundColor: colors.primary.main, color: colors.text.secondary }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
                            <Typography variant="h5" component="div" sx={{ flexGrow: 1 }}>Action Summary</Typography>
                        </Box>
                        <Box>
                            <Typography variant="h5" component="div" sx={{ flexGrow: 1 }}>{asset && asset.name}</Typography>
                        </Box>
                    </Box>
                </Toolbar>
            </AppBar>

            <Box
                padding={1}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    height: '100%',
                    marginTop: '3.5rem'
                }}>
                <Box sx={{ width: '100%', padding: 1 }}>
                    {
                        periodList.map((period, index) => {
                            return (mileageDisplay(period, index));
                        })
                    }
                </Box>
            </Box>
            <Dialog open={open} onClose={handleCloseDialog}>
                <DialogTitle>
                    <span style={{ color: colors.primary.main }}> <b>Fuel Events: </b></span><i>{thePeriod}</i>
                </DialogTitle>
                <DialogContent>
                    {selectedData && selectedData.events.map((item, index) => (
                        <Box key={index} sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            justifyContent: 'flex-start',
                            mb: 1,
                            padding: 1,
                            borderRadius: '5px',
                            backgroundColor: colors.grey.shade3
                        }}>
                            <Typography variant="h5" sx={{ color: colors.text.lighter }}>
                                <span
                                    style={{ color: item.fuel_event_type === 'filling' ? colors.freenzomp.main : colors.redfire.main }}>
                                    <b>{item.fuel_event_type.toUpperCase()}: {`${item.fuel_event_volume} L`}</b>
                                </span>
                            </Typography>
                            <Typography variant="body1" sx={{ color: colors.text.primary }}>
                                <span style={{ color: colors.text.lighter }}> <b>Time:</b></span>{` ${moment(item.eventTime).format('DD-MMM-YYYY h:mm a')}`}
                            </Typography>
                            <Typography variant="body1" sx={{ color: colors.text.primary }}>
                                <span style={{ color: colors.text.lighter }}> <b>Before:</b></span> {`${item.startVol} L`}
                            </Typography>
                            <Typography variant="body1" sx={{ color: colors.text.primary }}>
                                <span style={{ color: colors.text.lighter }}> <b>After:</b></span> {`${item.endVol} L`}
                            </Typography>
                            <Typography
                                variant="subtitle2"
                                onClick={() => window.open(`https://www.google.com/maps/search/?api=1&query=${item.lat},${item.lng}`, "_blank")}
                                rel="noopener noreferrer"
                                sx={{ cursor: 'pointer', color: colors.primary.shade11, textDecoration: 'underline' }}
                            >
                                <span style={{ color: colors.text.primary }}> <b>Show on Google Maps </b></span>
                            </Typography>
                        </Box>
                    ))}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}>Close</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default ActionSummary;


/* <Typography variant="h3" sx={{ color: colors.primary.main }}>Coming Soon ...</Typography>
                <br />
                <Button variant="contained" color="secondary" onClick={goback}>Go Back</Button> */