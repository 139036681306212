// src/pages/Signin.js
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    TextField, Button, Checkbox, FormControlLabel,
    Box, Container, Typography,
} from '@mui/material';
import { Avatar } from '@mui/material';
import { theme } from '../theme';
import { loginUser, useridSelector, errorSelector } from '../redux/user/userSlice';
import { useNavigate } from 'react-router-dom';

function Signin() {
    //const theme = useTheme(theme);
    const colors = theme.palette;

    const dispatch = useDispatch();
    const navigate = useNavigate(); // Assign useNavigate to a variable

    const userid = useSelector(useridSelector);

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [rememberMe, setRememberMe] = useState(true);
    const isLoading = useSelector((state) => state.user.isFetching);
    const errorText = useSelector(errorSelector);

    useEffect(() => {
        if (userid) {
            //console.log("USer is logged in", userid);
            navigate('/fleet-dashboard'); // Use navigate to navigate to '/fleet-dashboard'
        }
    }, [userid, navigate])


    useEffect(() => {
        const savedUsername = localStorage.getItem('username');
        const savedPassword = localStorage.getItem('password');
        if (savedUsername && savedPassword) {
            dispatch(loginUser({ ilogusername: savedUsername, ilogpassword: savedPassword }));
            //console.log("Saved maal niye login ta nijei gelo", savedUsername, savedPassword);
        }
    }, [dispatch]);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (rememberMe) {
            localStorage.setItem('username', username);
            localStorage.setItem('password', password);
        }
        dispatch(loginUser({ ilogusername: username, ilogpassword: password }));
        //console.log("LOgin pathalam", username, password);
    };

    return (
        <Container sx={{ backgroundColor: colors.grey.shade9 }}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: colors.grey.shade1,
                    borderRadius: '8px',
                    padding: 2,
                    width: '100%',
                }}
            >
                <Avatar sx={{ width: '150px', height: '150px', marginBottom: '1rem', backgroundColor: colors.grey.shade2 }}>
                    <img src={'/cuboid.png'} alt="Roadmatics Logo" style={{ width: '90%', height: '90%', objectFit: 'contain', margin: '10px' }} />
                </Avatar>
                <Typography variant="h5" component="h2" color={colors.primary.main}>
                    Roadmatics iLogistek 3.0
                </Typography>
                <Typography variant="h3" component="h4" color={colors.secondary.main}>
                    Diesel Monitoring
                </Typography>
                <br />
                <Box
                    component="form"
                    onSubmit={handleSubmit}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        marginTop: '0rem',
                        marginBottom: '0rem',
                        backgroundColor: colors.grey.shade2,
                        padding: '1rem',
                        borderRadius: '5px',
                        width: '80vw',
                    }}
                >
                    <Typography variant="h3" component="h4" color={colors.primary.main}>
                        <b>Login</b>
                    </Typography>
                    <TextField
                        label={<Typography variant="body1" color={colors.primary.main}>Username</Typography>}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                    <TextField
                        label={<Typography variant="body1" color={colors.primary.main}>Password</Typography>}
                        type="password"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <FormControlLabel
                        control={<Checkbox sx={{ color: colors.primary.main }} checked={rememberMe} onChange={(e) => setRememberMe(e.target.checked)} />}
                        label={<Typography variant="body1" color={colors.primary.main}>Remember me</Typography>}
                    />
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={isLoading || !username || !password}
                        sx={{ marginTop: '1rem', width: '100%' }}
                    >
                        {isLoading ? 'Signing in...' : 'Sign In'}
                    </Button>
                    {errorText && (
                        <Typography variant="body2" color="error" sx={{ marginTop: '1rem' }}>
                            Login failed: <b>{errorText}</b>
                        </Typography>
                    )}
                </Box>
                <Typography variant="subtitle1" sx={{ marginTop: '0.5rem' }} color={colors.primary.main}>
                    Roadmatics Technologies Private Limited
                </Typography>
                <Typography variant="body1" sx={{ marginTop: '0.5rem' }} color={colors.primary.main}>
                    Support: <a href="tel:+919372800056"><b>93728 00056</b></a>
                </Typography>
            </Box>
        </Container>
    );
}

export default Signin;