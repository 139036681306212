import React from 'react';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { useSelector } from 'react-redux';
import { userSelector } from './redux/user/userSlice';

import './App.css';

import Signin from './pages/Signin';
import FleetDashboard from './pages/FleetDashboard';
import FuelDetails from './pages/FuelDetails';
import Dashboard from './pages/Dashboard';
import Reports from './pages/Reports';
import FuelActivityReport from './pages/FuelActivityReport';
import ActionSummary from './pages/ActionSummary';


function App() {

  const user = useSelector(userSelector);
  const { userid } = user;
  //console.log("USER at app.jsx", user);
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={userid ? <FleetDashboard /> : <Signin />} />
        <Route exact path="/fleet-dashboard" element={<FleetDashboard />} />
        <Route exact path="/fuel-details" element={<FuelDetails />} />
        <Route exact path="/dashboard" element={<Dashboard />} />
        <Route exact path="/reports" element={<Reports />} />
        <Route exact path="/fuel-activity-report" element={<FuelActivityReport />} />
        <Route exact path="/action-summary" element={<ActionSummary />} />
      </Routes>
    </Router>
  );

}

export default App;

//const [theme, colorMode] = useMode();

/* return (
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="aspect-ratio-container">
        <div className="aspect-ratio-content">
          <Router>
            <Routes>
              <Route path="/" element={<Signin />} />
              <Route path="/fleet-dashboard" element={<FleetDashboard />} />
              <Route path="/fuel-details" element={<FuelDetails />} />
              <Route path="/special" element={<Special />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/reports" element={<Reports />} />
              <Route path="/fuel-activity-report" element={<FuelActivityReport />} />
            </Routes>
          </Router>
        </div>
      </div>
    </ThemeProvider>
  </Provider>
); */