import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
    reportDataSelector, reportDetailViewSelector,
    setReportDetailView, deleteReport, getReportList,
    downloadingReportDataSelector,
} from '../redux/datacenter/datacenterSlice';
import { detailViewSelector, rackSelector } from '../redux/assets/assetsSlice';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';

import { theme } from '../theme';
import {
    Box,
    AppBar, Toolbar, Typography,
    Card, CardHeader, CardContent,
    CircularProgress, Button,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

const FuelActivityReport = () => {

    const reportData = useSelector(reportDataSelector);
    const reportDetailView = useSelector(reportDetailViewSelector);
    const report = reportData[reportDetailView];
    const downloadingReport = useSelector(downloadingReportDataSelector);

    const rack = useSelector(rackSelector);
    const detailView = useSelector(detailViewSelector);
    const asset = rack[detailView];

    //console.log("This report", report);

    const dispatch = useDispatch();
    const colors = theme.palette;

    const navigate = useNavigate();

    useEffect(() => {
        if (!asset || !reportDetailView) {
            navigate('/reports');
        }
    }, [navigate, asset, reportDetailView])



    const [ready, setReady] = useState(false);
    const [distReady, setDistReady] = useState(false);
    const [plotData, setPlotData] = useState(null);
    const [disPlotData, setDisPlotData] = useState(null);
    const [plotAvailable, setPlotAvailable] = useState(false);
    const [distPlotAvailable, setDistPlotAvailable] = useState(false);
    const [maxLevel, setMaxLevel] = useState({ min: 0, max: 50 });
    const [distMaxLevel, setDistMaxLevel] = useState({ min: 0, max: 50 });

    useEffect(() => {
        if (report) {
            const { fuelPlotData, fuelDistPlotData } = report;
            if (fuelPlotData && fuelPlotData.length > 1) {
                let pdata = [];
                fuelPlotData.forEach(p => {
                    if (!isNaN(p.vol)) {
                        pdata.push({ name: p.time, value: p.vol, unit: 'Liter' });
                    }
                })
                setPlotData(pdata);
                setPlotAvailable(true);
                let levelArr = fuelPlotData.map(p => parseFloat(p.vol));
                levelArr = levelArr.filter(p => !isNaN(p));
                const max = Math.max(...levelArr) + 10;
                const min = Math.min(...levelArr) - 10;
                //console.log("Time plot min max", min, max);
                setMaxLevel({ min, max });
            }

            if (fuelDistPlotData && fuelDistPlotData.length > 5) {
                let pdistdata = [];
                fuelDistPlotData.forEach(p => {
                    if (!isNaN(p.dist) && !isNaN(p.vol)) {
                        pdistdata.push({ name: p.dist.toFixed(0), value: p.vol, unit: 'Liter', time: p.time });
                    }
                })
                setDisPlotData(pdistdata);
                setDistPlotAvailable(true);
                let levelArrDist = fuelDistPlotData.map(p => parseFloat(p.vol));
                levelArrDist = levelArrDist.filter(p => !isNaN(p));
                const max = Math.max(...levelArrDist) + 10;
                const min = Math.min(...levelArrDist) - 10;
                //console.log("Dist plot min", min, "max", max, levelArrDist);
                setDistMaxLevel({ min, max });
            }
            //console.log("Dist Plot", fuelDistPlotData);
            //console.log("report", report);
        }
    }, [report, ready]);

    useEffect(() => {
        if (report) {
            const { fuelPlotData, fuelDistPlotData, startLoc, endLoc } = report;
            if (fuelPlotData && fuelPlotData.length > 0) {
                if (startLoc && endLoc) {
                    setReady(true);
                }

            }
            if (fuelDistPlotData && fuelDistPlotData.length > 0) {
                if (startLoc && endLoc) {
                    setDistReady(true);
                }

            }
        }

        //console.log("Report", report);
    }, [report])

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip">
                    <p className="label"><b>{`Distance: ${label} Km`}</b></p>
                    <p className="label">{`Fuel: ${payload[0].payload.value} Liters`}</p>
                    <p className="labeel">{`${payload[0].payload.time}`}</p>
                </div>
            );
        }

        return null;
    };

    const deleteNoDataReport = (reportID) => {
        console.log("first delete", reportID);
        if (reportDetailView === reportID) {
            dispatch(setReportDetailView(null));
        }
        dispatch(deleteReport(reportID));
        dispatch(getReportList());
        navigate('/reports', { state: { asset } });
    }

    if (reportDetailView === null) {
        return (
            <div>
                <h1>No report selected</h1>
            </div>
        )
    } else if (downloadingReport) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                    width: '100%'
                }}>
                <CircularProgress />
                <Typography variant="h6" sx={{ color: colors.text.lighter }}>Downloading Report Data</Typography>
                <br />
                <br />
                <Button
                    variant="contained"
                    onClick={() => deleteNoDataReport(report.reportID)}
                    sx={{ color: colors.text.secondary }}
                    color="redfire"
                >
                    <DeleteIcon /> Cancel & Go Back
                </Button>
            </Box>
        )
    }
    else if (!report.dataTable) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                    width: '100%'
                }}>
                <Typography variant="h4" sx={{ color: colors.primary.main }}>Report Data Not Found</Typography>
                <Typography variant="h6" sx={{ color: colors.text.lighter }}>Please try again later</Typography>
                <br />
                <br />
                <Button
                    variant="contained"
                    onClick={() => deleteNoDataReport(report.reportID)}
                    sx={{ color: colors.text.secondary }}
                    color="redfire"
                >
                    <DeleteIcon /> Delete & Go Back
                </Button>
            </Box>
        )
    }
    else {
        return (
            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh', width: '100%' }}>
                <AppBar position="fixed">
                    <Toolbar sx={{ backgroundColor: colors.primary.main, color: colors.text.secondary }}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
                                <Typography variant="h5" component="div" sx={{ flexGrow: 1 }}>Trip Report with Fuel</Typography>
                            </Box>
                            <Box>
                                <Typography variant="h5" component="div" sx={{ flexGrow: 1 }}>{asset.name}</Typography>
                            </Box>
                        </Box>
                    </Toolbar>
                </AppBar>
                {
                    !report.dataTable ?
                        <CircularProgress />
                        :
                        <Box padding={1}>
                            <Box sx={{ height: '50px' }} />
                            <Card sx={{ backgroundColor: colors.grey.shade1, color: colors.primary.main, mb: 1 }}>
                                <CardContent>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                                        <Typography variant="body1" sx={{ color: colors.primary.shade12 }}>
                                            <b>From:</b>
                                        </Typography>
                                        <Typography variant="h5" sx={{ color: colors.text.lighter }}>
                                            <b>{moment(report.startTime).format('DD-MMM-YYYY h:mm a')}</b>
                                        </Typography>
                                        <Typography variant="body2" sx={{ color: colors.text.lighter }}>
                                            {report && report.startLoc && report.startLoc.fmtd_addr}
                                        </Typography>
                                        <br />
                                        <Typography variant="h5" sx={{ color: colors.primary.shade12 }}>
                                            <b>To:</b>
                                        </Typography>
                                        <Typography variant="body1" sx={{ color: colors.text.lighter }}>
                                            <b>{moment(report.endTime).format('DD-MMM-YYYY h:mm a')}</b>
                                        </Typography>
                                        <Typography variant="body2" sx={{ color: colors.text.lighter }}>
                                            {report && report.endLoc && report.endLoc.fmtd_addr}
                                        </Typography>
                                    </Box>
                                </CardContent>
                            </Card>
                            <Card sx={{ backgroundColor: 'white', color: colors.primary.main, mb: 1 }}>
                                <CardHeader
                                    title={
                                        <Typography variant="h4" sx={{ color: colors.yellow.shade14 }}><b>Summary</b></Typography>
                                    }
                                />
                                <CardContent>
                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'flex-start',
                                        justifyContent: 'flex-start',
                                        padding: 1,
                                        borderRadius: '5px',
                                        backgroundColor: colors.grey.shade1
                                    }}>
                                        <Typography variant="body1" sx={{ color: colors.text.lighter }}>
                                            <span style={{ color: colors.yellow.shade11 }}> <b>Distance:</b></span> {report.totalDistance} km
                                        </Typography>
                                        <br />
                                        <Typography variant="h5" sx={{ color: colors.text.lighter }}>
                                            <span style={{ color: colors.yellow.shade11 }}> <b>Consumed:</b></span> {report.consumption} L
                                        </Typography>
                                        <br />
                                        <Typography variant="h5" sx={{ color: colors.text.lighter }}>
                                            <span style={{ color: colors.yellow.shade11 }}> <b>Mileage:</b> </span>{report.mileage} km/L
                                        </Typography>
                                        <br />
                                    </Box>
                                </CardContent>
                            </Card>
                            <Card sx={{ backgroundColor: 'white', color: colors.primary.main, mb: 1 }}>
                                <CardHeader
                                    title={
                                        <Typography variant="h4" sx={{ color: colors.freenzomp.shade12 }}><b>Tank Levels</b></Typography>
                                    }
                                />
                                <CardContent>
                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'flex-start',
                                        justifyContent: 'flex-start',
                                        padding: 1,
                                        borderRadius: '5px',
                                        backgroundColor: colors.grey.shade1
                                    }}>
                                        <Typography variant="body1" sx={{ color: colors.text.lighter }}>
                                            <span style={{ color: colors.freenzomp.shade9 }}> <b>Start Vol:</b></span> {report.startLevel} L
                                        </Typography>
                                        <br />
                                        <Typography variant="h5" sx={{ color: colors.text.lighter }}>
                                            <span style={{ color: colors.freenzomp.shade9 }}> <b>End Vol:</b></span> {report.endLevel} L
                                        </Typography>
                                    </Box>
                                </CardContent>
                            </Card>
                            <Card sx={{ backgroundColor: 'white', color: colors.primary.main, mb: 1 }}>
                                <CardHeader
                                    title={
                                        <Typography variant="h4" sx={{ color: colors.secondary.main }}><b>Tank Activity</b></Typography>
                                    }
                                />
                                <CardContent>
                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'flex-start',
                                        justifyContent: 'flex-start',
                                        padding: 1,
                                        borderRadius: '5px',
                                        backgroundColor: colors.grey.shade1
                                    }}>
                                        <Typography variant="body1" sx={{ color: colors.text.lighter }}>
                                            <span style={{ color: colors.secondary.shade9 }}> <b>Filling:</b></span> {`${report.totalFills} L`} {` (${report.fillCount} times)`}
                                        </Typography>
                                        <br />
                                        <Typography variant="h5" sx={{ color: colors.text.lighter }}>
                                            <span style={{ color: colors.secondary.shade9 }}> <b>Theft:</b></span> {`${report.totalTheft} L`} {` (${report.theftCount} times)`}
                                        </Typography>
                                    </Box>
                                </CardContent>
                            </Card>
                            {
                                report.fillsAndThefts && report.fillsAndThefts.length > 0 &&
                                <Card sx={{ backgroundColor: 'white', color: colors.primary.main, mb: 1 }}>
                                    <CardHeader
                                        title={
                                            <Typography variant="h4" sx={{ color: colors.redfire.shade12 }}><b>Fill & Theft List</b></Typography>
                                        }
                                    />
                                    <CardContent>
                                        {
                                            report.fillsAndThefts.map((item, index) => (
                                                <Box key={index} sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'flex-start',
                                                    justifyContent: 'flex-start',
                                                    mb: 1,
                                                    padding: 1,
                                                    borderRadius: '5px',
                                                    backgroundColor: colors.grey.shade1
                                                }}>
                                                    <Typography variant="h5" sx={{ color: colors.text.lighter }}>
                                                        <span style={{ color: colors.text.primary }}> <b>{item.event_type.toUpperCase()}: {`${item.volume} L`}</b></span>
                                                    </Typography>
                                                    <Typography variant="body1" sx={{ color: colors.text.lighter }}>
                                                        <span style={{ color: colors.text.primary }}> <b>Time:</b></span>{` ${moment(item.time).format('DD-MMM-YYYY h:mm a')}`}
                                                    </Typography>
                                                    <Typography variant="body1" sx={{ color: colors.text.lighter }}>
                                                        <span style={{ color: colors.text.primary }}> <b>Before:</b></span> {`${item.startVol} L`}
                                                    </Typography>
                                                    <Typography variant="body1" sx={{ color: colors.text.lighter }}>
                                                        <span style={{ color: colors.text.primary }}> <b>After:</b></span> {`${item.endVol} L`}
                                                    </Typography>
                                                    <Typography
                                                        variant="subtitle2"
                                                        onClick={() => window.open(`https://www.google.com/maps/search/?api=1&query=${item.lat},${item.lng}`, "_blank")}
                                                        rel="noopener noreferrer"
                                                        sx={{ cursor: 'pointer', color: colors.primary.shade11, textDecoration: 'underline' }}
                                                    >
                                                        <span style={{ color: colors.text.primary }}> <b>Location: </b></span> {item.address.fmtd_addr}
                                                    </Typography>
                                                </Box>
                                            ))
                                        }
                                    </CardContent>
                                </Card>
                            }
                            {plotAvailable &&
                            <Card sx={{ backgroundColor: 'white', color: colors.primary.main, mb: 1 }}>
                                <CardHeader
                                    title={
                                        <Typography variant="h4" sx={{ color: colors.primary.main }}><b>Fuel vs Time Graph</b></Typography>
                                    }
                                />
                                <CardContent>

                                        <ResponsiveContainer width="95%" height={400}>
                                            <LineChart data={plotData} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
                                                <Line
                                                    name="Fuel in Tank"
                                                    type="linear"
                                                    dataKey="value"
                                                    stroke="dodgerblue"
                                                    dot={{ stroke: 'blue', strokeWidth: 1, r: 1 }}
                                                    activeDot={{ stroke: 'midnightblue', strokeWidth: 2, r: 2 }}
                                                />
                                                <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                                                <XAxis dataKey="name" />
                                                <YAxis domain={[maxLevel.min, maxLevel.max]} />
                                                <Tooltip />
                                            </LineChart>
                                        </ResponsiveContainer>

                                </CardContent>
                            </Card>
                            }
                            {distPlotAvailable && distReady &&
                                <Card sx={{ backgroundColor: 'white', color: colors.primary.main, mb: 1 }}>
                                    <CardHeader
                                        title={
                                            <Typography variant="h4" sx={{ color: colors.freenzomp.main }}><b>Fuel vs Kilometer Graph</b></Typography>
                                        }
                                    />
                                    <CardContent>

                                        <ResponsiveContainer width="95%" height={300}>
                                            <LineChart data={disPlotData} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
                                                <Line
                                                    name="Fuel in Tank"
                                                    type="linear"
                                                    dataKey="value"
                                                    stroke="ForestGreen"
                                                    dot={{ stroke: 'LimeGreen', strokeWidth: 1, r: 1 }}
                                                    activeDot={{ stroke: 'Green', strokeWidth: 2, r: 2 }}
                                                />
                                                <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                                                <XAxis dataKey="name" />
                                                <YAxis domain={[distMaxLevel.min, distMaxLevel.max]} />
                                                <Tooltip content={<CustomTooltip />} />
                                            </LineChart>
                                        </ResponsiveContainer>
                                    </CardContent>
                                </Card>
                            }
                            <Card sx={{ backgroundColor: colors.primary.shade13, color: colors.primary.main, mb: 1 }}>
                                <CardHeader
                                    title={
                                        <Typography variant="h4" sx={{ color: colors.text.secondary }}><b>End of Report</b></Typography>
                                    }
                                    action={
                                        <Button
                                            variant="text"
                                            onClick={() => deleteNoDataReport(report.reportID)}
                                            sx={{ color: colors.text.secondary }}
                                        >
                                            <DeleteIcon /> Delete
                                        </Button>
                                    }
                                />
                            </Card>
                        </Box>
                }
            </Box>
        )
    }

}

export default FuelActivityReport;