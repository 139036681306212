import { apiClient } from './axiosAdvanced';

export const getPumpDets = async (lat, lng) => {
    try {
        const apiData = await apiClient.post('/olapumps', { lat, lng });
        const result = apiData.data.data;
        return result;
    } catch (error) {
        console.log("Get Pump Error")
        return { locationAddress: null, pumpname: null };
    }
}



export const getQuickLocation = async (lat, lng) => {
    try {
        const apiData = await apiClient.post('/olaquickloc', { lat, lng });
        const result = apiData.data.data;
        return result;
    } catch (error) {
        console.log("Get Location Error")
        return { locationAddress: null };
    }
}