import moment from 'moment';

moment.updateLocale('en', {
    week: {
        dow: 1, // Monday is the first day of the week
        doy: 4  // The week that contains Jan 4th is the first week of the year
    }
});

export const periodList = ['today', 'yesterday', 'sinceLastFilling', 'thisWeek', 'lastWeek', 'thisMonth', 'lastMonth'];
export const autoLoadPeriods = ['today', 'yesterday', 'sinceLastFilling'];


export const periodNameList = {
    today: 'Today',
    yesterday: 'Yesterday',
    yesterdayTillNow: 'Yesterday Till Now',
    lastTwoDays: 'Last Two Days',
    thisWeek: 'This Week',
    lastWeek: 'Last Week',
    thisMonth: 'This Month',
    lastMonth: 'Last Month',
    thisQuarter: 'This Quarter',
    thisYear: 'This Year',
    sinceLastFilling: 'Since Last Filling',
};

export const lastEventTimeRange = (eventTime) => {
    let timeFrom, timeTo;
    timeFrom = moment(eventTime).add(20, 'minutes').format('YYYY-MM-DD HH:mm:ss');
    timeTo = moment().format('YYYY-MM-DD HH:mm:ss');
    const periodName = 'Since Last Filling';
    return { timeFrom, timeTo, periodName };
}

export const getPeriodRange = (period) => {
    let timeFrom, timeTo, periodName;

    switch (period) {
        case 'today':
            timeFrom = moment().startOf('day').format('YYYY-MM-DD HH:mm:ss');
            timeTo = moment().format('YYYY-MM-DD HH:mm:ss');
            periodName = 'Today';
            break;
        case 'yesterday':
            timeFrom = moment().subtract(1, 'day').startOf('day').format('YYYY-MM-DD HH:mm:ss');
            timeTo = moment().subtract(1, 'day').endOf('day').format('YYYY-MM-DD HH:mm:ss');
            periodName = 'Yesterday';
            break;
        case 'yesterdayTillNow':
            timeFrom = moment().subtract(1, 'day').startOf('day').format('YYYY-MM-DD HH:mm:ss');
            timeTo = moment().format('YYYY-MM-DD HH:mm:ss');
            periodName = 'Yesterday Till Now';
            break;
        case 'lastTwoDays':
            timeFrom = moment().subtract(2, 'day').startOf('day').format('YYYY-MM-DD HH:mm:ss');
            timeTo = moment().format('YYYY-MM-DD HH:mm:ss');
            periodName = 'Last Two Days';
            break;
        case 'thisWeek':
            timeFrom = moment().startOf('week').format('YYYY-MM-DD HH:mm:ss');
            timeTo = moment().format('YYYY-MM-DD HH:mm:ss');
            periodName = 'This Week';
            break;
        case 'lastWeek':
            timeFrom = moment().subtract(1, 'week').startOf('week').format('YYYY-MM-DD HH:mm:ss');
            timeTo = moment().subtract(1, 'week').endOf('week').format('YYYY-MM-DD HH:mm:ss');
            periodName = 'Last Week';
            break;
        case 'thisMonth':
            timeFrom = moment().startOf('month').format('YYYY-MM-DD HH:mm:ss');
            timeTo = moment().format('YYYY-MM-DD HH:mm:ss');
            periodName = 'This Month';
            break;
        case 'lastMonth':
            timeFrom = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD HH:mm:ss');
            timeTo = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD HH:mm:ss');
            periodName = 'Last Month';
            break;
        case 'thisQuarter':
            timeFrom = moment().startOf('quarter').format('YYYY-MM-DD HH:mm:ss');
            timeTo = moment().format('YYYY-MM-DD HH:mm:ss');
            periodName = 'This Quarter';
            break;
        case 'thisYear':
            timeFrom = moment().startOf('year').format('YYYY-MM-DD HH:mm:ss');
            timeTo = moment().format('YYYY-MM-DD HH:mm:ss');
            periodName = 'This Year';
            break;
        default:
            timeFrom = moment().subtract(1, 'day').format('YYYY-MM-DD HH:mm:ss');
            timeTo = moment().format('YYYY-MM-DD HH:mm:ss');;
            break;
    }
    return { timeFrom, timeTo, periodName };
};