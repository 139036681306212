import React, { useState } from 'react';
import moment from 'moment';


import { theme } from '../theme';
import { Box, Divider, Typography } from '@mui/material';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, } from '@mui/material';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';



function FillCard({ lastEvent, asset, pumpname, pumpAddress }) {

    const colors = theme.palette;
    const [addressOpen, setAddressOpen] = useState(false);

    const handleClose = () => {
        setAddressOpen(false);
    }

    return (
        <div>
            <Card sx={{ with: '95%', backgroundColor: colors.gainsboro.shade2 }}>
            <CardHeader
                action={
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-end',
                            alignItems: 'flex-end',
                            padding: 1,
                            backgroundColor: colors.gainsboro.shade5,
                            borderRadius: '5px',
                        }}>
                            <Typography variant="subtitle2" sx={{ color: colors.grey.shade13, fontSize: '0.7rem' }}>
                                {moment(lastEvent.eventTime).add(330, 'minutes').format('h:mm A')}
                            </Typography>
                            <Divider />
                            <Typography variant="h4" sx={{ color: colors.grey.shade13 }}>
                                <b>{moment(lastEvent.eventTime).add(330, 'minutes').format('D-MMM')}</b>
                            </Typography>
                    </Box>
                }
                title={
                    <>
                        <Typography variant="h4" sx={{ color: colors.secondary.main }}>Last Filling</Typography>
                        <Typography
                            variant="subtitle2" sx={{ marginRight: 2, color: colors.text.lighter }}
                        >
                            {pumpname !== '' ? pumpname : lastEvent.pumpName}
                        </Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                            {pumpAddress && <Typography
                                variant="subtitle2"
                                onClick={() => addressOpen ? setAddressOpen(false) : setAddressOpen(true)}
                                rel="noopener noreferrer"
                                sx={{ cursor: 'pointer', color: colors.primary.shade11, textDecoration: 'underline', marginRight: 1 }}
                            >
                                Address
                            </Typography>}
                            <Typography
                                variant="subtitle2"
                                onClick={() => window.open(`https://www.google.com/maps/search/?api=1&query=${lastEvent.lat},${lastEvent.lng}`, "_blank")}
                                rel="noopener noreferrer"
                                sx={{ cursor: 'pointer', color: colors.primary.shade11, textDecoration: 'underline' }}
                            >
                                Show {!pumpAddress && 'Pump Location'} on GMaps
                            </Typography>
                        </Box>
                    </>
                }
            />
            <CardContent>
                    <Box display="flex" flexDirection="row" justifyContent="space-evenly" alignItems="center">
                        <Box sx={{ width: '25%', height: 50, backgroundColor: colors.grey.shade2, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', padding: 1, borderRadius: '5px' }}>
                        <Typography variant="h5" color={colors.text.lighter}><b>Before</b></Typography>
                        <Typography variant="h5" color={colors.text.lighter}><b>{parseFloat(lastEvent.startVol).toFixed(0)}L</b></Typography>
                    </Box>
                        <Box sx={{ width: '40%', height: 50, backgroundColor: colors.freenzomp.shade2, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', padding: 1, borderRadius: '5px' }}>
                            <Typography variant="h2" sx={{ color: colors.primary.shade11 }}><b>+ {parseFloat(lastEvent.fuel_event_volume).toFixed(0)}L</b></Typography>
                    </Box>
                        <Box sx={{ width: '25%', height: 50, backgroundColor: colors.grey.shade14, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', padding: 1, borderRadius: '5px' }}>
                            <Typography variant="h5" color={colors.text.secondary}><b>After</b></Typography>
                            <Typography variant="h5" color={colors.text.secondary}><b>{parseFloat(lastEvent.endVol).toFixed(0)}L</b></Typography>
                    </Box>
                    </Box>
                </CardContent>
            </Card>
            <Dialog open={addressOpen} onClose={handleClose}>
                <DialogTitle>Pump Address</DialogTitle>
                <DialogContent>
                    <Typography paragraph>{pumpAddress}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>

        </div>
    );
}

export default FillCard;