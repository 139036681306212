import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';


const authClient = axios.create({
    baseUrl: "https://mobileauth.roadmatics.com:10001"
})

const apiClient = axios.create({
    baseUrl: "https://mobileapi.roadmatics.com:10004"
});

const configApiClient = (token, hosts = null) => {

    if (hosts) {
        apiClient.defaults.baseURL = hosts.apiHost;
    }
    apiClient.defaults.headers.common['token'] = token;
    apiClient.defaults.withCredentials = true;
    //console.log("In configApi client header", hosts.apiHost);
    return;
}

// Function that will be called to refresh authorization
const refreshAuthLogic = failedRequest => authClient.post(
    '/token_refresh',
    {
        accessToken: axios.defaults.headers.common['token'],
        //refreshToken: axios.defaults.headers.common['refreshToken'],
    }
).then(tokenRefreshResponse => {
    //console.log("Token refresh response", tokenRefreshResponse);
    const { token, refreshToken } = tokenRefreshResponse;
    configApiClient(token, refreshToken);
    failedRequest.response.config.headers['token'] = tokenRefreshResponse.data.token;
    return Promise.resolve();
});

// Instantiate the interceptor (you can chain it as it returns the axios instance)
createAuthRefreshInterceptor(apiClient, refreshAuthLogic);

export { authClient, apiClient, configApiClient };
