import { createSlice } from '@reduxjs/toolkit';
import { assetHydrate, getMileage } from './assetsApi';

const baseMileage = {
    distance: null,
    mileage: null,
    consumption: null,
    status: null,
};

const assetsSlice = createSlice({
    name: 'assets',
    initialState: {
        isLoading: false,
        loaded: false,
        assetList: [],
        rack: {}, // Add an empty object for the rack property
        assetNames: {},
        visibleAssetsList: [],
        detailView: null,
    },
    reducers: {
        // Add your asset-related actions here
        searchAsset: (state, { payload }) => {
            const searchTerm = payload.toLowerCase();
            if (searchTerm === '') {
                state.visibleAssetsList = state.assetList.map(asset => asset.imei);
            } else {
                state.visibleAssetsList = state.assetList.filter(asset => asset.name.toLowerCase().includes(searchTerm)).map(asset => asset.imei);
            }
        },
        setDetailView: (state, { payload }) => {
            state.detailView = payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(assetHydrate.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(assetHydrate.fulfilled, (state, { payload }) => {
                //console.log("Recd hydration data", payload.length);
                state.isLoading = false;
                state.loaded = true;
                state.updated = Date.now();
                let rack = {};
                if (payload) {
                    payload.forEach((asset) => {
                        if (asset.imei) {
                            rack = {
                                ...rack,
                                [asset.imei]: {
                                    ...asset,
                                    action: {
                                        sinceLastFilling: baseMileage,
                                        today: baseMileage,
                                        yesterday: baseMileage,
                                        yesterdayTillNow: baseMileage,
                                        lastTwoDays: baseMileage,
                                        thisWeek: baseMileage,
                                        lastWeek: baseMileage,
                                        thisMonth: baseMileage,
                                        lastMonth: baseMileage,
                                        thisQuarter: baseMileage,
                                        thisYear: baseMileage,
                                    }
                                },
                                updated: Date.now(),
                            };
                        } else {
                            console.log("Asset issue", asset);
                        }
                    });
                    state.rack = rack;
                    let assetList = payload.map((asset) => ({ imei: asset.imei, name: asset.name }));
                    state.assetList = assetList;
                    state.visibleAssetsList = assetList.map(asset => asset.imei);
                    state.assetNames = payload.reduce((acc, asset) => {
                        acc[asset.id] = asset.name;
                        return acc;
                    }, {});
                }
            })
            .addCase(assetHydrate.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(getMileage.pending, (state, { meta }) => {
                state.rack[meta.arg.imei].action[meta.arg.period].status = 'loading';
            })
            .addCase(getMileage.fulfilled, (state, { payload }) => {
                state.rack[payload.imei].action[payload.period] = { ...payload.actionSummary, status: 'loaded', updated: Date.now() };
            })
            .addCase(getMileage.rejected, (state, { meta }) => {
                if (state.rack[meta.arg.imei]) {
                    console.log("Failed to fetch mileage for ", meta.arg.imei);
                    state.rack[meta.arg.imei].action[meta.arg.period].status = 'updateFailed';
                }

            });
    }
});

export const { actions } = assetsSlice;
export default assetsSlice.reducer;
export const { searchAsset, setDetailView } = assetsSlice.actions;

export { assetHydrate, getMileage };

export const loadingSelector = state => state.assets.isLoading;
export const assetsSelector = state => state.assets;
export const rackSelector = state => state.assets.rack;
export const assetListSelector = state => state.assets.assetList;
export const visibleAssetsListSelector = state => state.assets.visibleAssetsList;
export const assetNamesSelector = state => state.assets.assetNames;
export const detailViewSelector = state => state.assets.detailView;