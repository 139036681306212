import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { theme } from '../theme';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { detailViewSelector, rackSelector } from '../redux/assets/assetsSlice';
import {
    Box, Typography, AppBar, Toolbar,
    Card, CardHeader, CardContent, CardActions,
    Button,
    Dialog, DialogTitle, DialogContent, DialogActions,
} from '@mui/material';

import Avatar from '@mui/material/Avatar';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import PauseIcon from '@mui/icons-material/Pause';
import { LocalParking } from '@mui/icons-material';
import { getPumpDets, getQuickLocation } from '../utils/olaApi';
import FillCard from '../components/FillCard';

import Icon from '@mdi/react';
import {
    mdiOfficeBuildingMarker, mdiChartLine,
    mdiCarCoolantLevel
} from '@mdi/js';

const FuelDetails = () => {

    const navigate = useNavigate();

    const rack = useSelector(rackSelector);
    const detailView = useSelector(detailViewSelector);
    const asset = rack[detailView];

    const volume = asset && parseFloat(asset.tank_1);
    const tankSize = asset && parseFloat(asset.tankSize);
    const usableVolume = volume && tankSize && volume - 0.05 * tankSize;
    const mileage_1 = asset && parseFloat(asset.mileage_1);
    const mileage_overall = asset && asset.action.yesterdayTillNow.mileage ? asset.action.yesterdayTillNow.mileage === 'NA' ? parseFloat(mileage_1) : parseFloat(asset.action.yesterdayTillNow.mileage) : parseFloat(mileage_1);
    const range = mileage_overall * usableVolume;

    const colors = theme.palette;

    const parsedTankSize = asset && parseFloat(asset.tankSize);
    const parsedTank_1 = asset && parseFloat(asset.tank_1);
    const progress = (parsedTank_1 / parsedTankSize) * 100;
    const proColor = progress > 20 ? 'primary' : 'redfire';

    const [pumpAddress, setPumpAddress] = useState('');
    const [pumpname, setPumpname] = useState('');
    const [locationAddress, setLocationAddress] = useState('');

    const [mileageClarificationOpen, setMileageClarificationOpen] = useState(false);
    const handleMileageClarificationClose = () => {
        setMileageClarificationOpen(false);
    };

    const [rangeClarificationOpen, setRangeClarificationOpen] = useState(false);
    const handleRangeClarificationClose = () => {
        setRangeClarificationOpen(false);
    };


    useEffect(() => {
        if (!asset) {
            navigate('/');
        }
    }, [navigate, asset]);

    useEffect(() => {
        if (asset && asset.lastEvent && asset.lastEvent.lat && asset.lastEvent.lng) {
            getPumpDets(asset.lastEvent.lat, asset.lastEvent.lng).then((res) => {
                if (res) {
                    setPumpAddress(res.locationAddress);
                    setPumpname(res.pumpname);
                }
            });
        }
        if (asset && asset.lat && asset.lng) {
            getQuickLocation(asset.lat, asset.lng).then((res) => {
                if (res) {
                    setLocationAddress(res);
                }
            });
        }
    }, [asset]);

    const assetStatus = () => {
        if (parseInt(asset.acc) === 1) {
            if (parseInt(asset.speed) > 4) {
                return { text: `Running: ${asset.speed} Km/h`, color: colors.freenzomp.shade12, icon: <ArrowCircleRightIcon sx={{ borderRadius: '50%' }} /> };
            }

            return {
                text: 'Idle', color: colors.secondary.shade13, icon: <PauseIcon sx={{ color: colors.text.secondary }} />
            };
        } else {
            return { text: 'Parked', color: colors.yellow.shade13, icon: <LocalParking /> };
        }
    }

    const showReports = () => {
        navigate('/reports');
    };

    const actionSummary = () => {
        navigate('/action-summary');
    };

    if (!asset) {
        return null;
    } else {
        return (
            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh', width: '100%' }}>
                <AppBar position="fixed">
                    <Toolbar sx={{ backgroundColor: colors.primary.main, color: colors.text.secondary }}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
                                <Typography variant="h5" component="div" sx={{ flexGrow: 1 }}>Details</Typography>
                            </Box>
                            <Box>
                                <Typography variant="h5" component="div" sx={{ flexGrow: 1 }}>{asset.name}</Typography>
                            </Box>
                        </Box>
                    </Toolbar>
                </AppBar>

                <Box padding={1} sx={{ marginTop: '25px' }}>
                    <Box sx={{ height: '30px' }} />
                    <Card sx={{ width: '100%', backgroundColor: 'white', marginBottom: '10px' }} elevation={0}>
                        <CardHeader
                            title={<Typography variant="h3" sx={{ color: colors.text.primary }}>{asset.name}</Typography>}
                            subheader={<Typography variant="h5" sx={{ color: colors.text.fader }}>{`${asset.model} | Tank: ${asset.tankSize}L`}</Typography>}
                        />
                        <CardContent>
                            <Box display="flex" justifyContent="flex-start" alignItems="center" flexDirection="row" sx={{ width: '100%', marginTop: '1rem' }}>
                                <Avatar
                                    alt={asset.tank_1}
                                    sx={{ width: 50, height: 50, backgroundColor: assetStatus().color }}
                                >
                                    {assetStatus().icon}
                                </Avatar>
                                <Box display="flex" justifyContent="center" alignItems="flex-start" flexDirection="column" sx={{ marginLeft: '5px' }}>
                                    <Typography variant="h5" sx={{ color: assetStatus().color }}>{assetStatus().text}</Typography>
                                    <Typography
                                        variant="body2"
                                        color="text.primary"
                                    >
                                        <b>Updated</b>: {moment(asset.dt_tracker).format('h:mm A, D-MMM ')}
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        color="text.primary"
                                    >
                                        <b>Odo</b>: {parseFloat(asset.odometer).toFixed(0)} Km
                                    </Typography>
                                    {
                                        parseInt(asset.alapwr) === 1 &&
                                        < Typography
                                            variant="body2"
                                            sx={{ color: colors.redfire.main }}
                                        >
                                            <b>Device Cutoff</b>
                                        </Typography>
                                    }
                                </Box>
                            </Box>
                            <Box display="flex" justifyContent="flex-start" alignItems="center" flexDirection="row" sx={{ width: '100%', marginTop: '1rem' }}>
                                <Avatar sx={{ bgcolor: colors.freenzomp.main, width: 50, height: 50 }}>
                                    <Icon path={mdiOfficeBuildingMarker} size={1} />
                                </Avatar>
                                <Box display="flex" justifyContent="center" alignItems="flex-start" flexDirection="column" sx={{ marginLeft: '5px' }}
                                    onClick={() => window.open(`https://www.google.com/maps/search/?api=1&query=${asset.lat},${asset.lng}`, "_blank")}
                                >
                                    <Typography variant="h5" sx={{ color: colors.freenzomp.main, marginLeft: '5px' }}>Address:</Typography>
                                    <Typography variant="body2" color={colors.text.primary} sx={{ marginRight: '5px', marginLeft: '5px' }}>
                                        {locationAddress} | <span style={{ color: colors.primary.shade11, cursor: 'pointer', textDecoration: 'underline' }}>Show on GMaps</span>
                                    </Typography>
                                </Box>
                            </Box>
                            <Box display="flex" justifyContent="flex-start" alignItems="center" flexDirection="row" sx={{ width: '100%', marginTop: '1rem' }}>
                                <Avatar sx={{ bgcolor: colors.secondary.main, width: 50, height: 50 }}>
                                    <Icon path={mdiChartLine} size={1} />
                                </Avatar>
                                <Box display="flex" justifyContent="center" alignItems="flex-start" flexDirection="column" sx={{ marginLeft: '5px' }}>
                                    {
                                        asset.action.yesterdayTillNow.mileage ?
                                            <Typography variant="body2" color={colors.text.primary} sx={{ marginRight: '5px', marginLeft: '5px' }}>
                                                <b>Mileage: </b>{
                                                    asset.action.yesterdayTillNow.mileage ?
                                                        asset.action.yesterdayTillNow.mileage === 'NA' ?
                                                            `${asset.mileage_1} Km/L (check later)` :
                                                            `${asset.action.yesterdayTillNow.mileage} Km/L` :
                                                        <span style={{ color: colors.primary.shade11, cursor: 'pointer', textDecoration: 'underline' }} onClick={() => setMileageClarificationOpen(true)}><i>{asset.mileage_1} Km/L (Check later)</i></span>
                                                }
                                            </Typography>
                                            :
                                            <Typography variant="body2" color={colors.text.primary} sx={{ marginRight: '5px', marginLeft: '5px' }}>
                                                <b>Mileage: </b>{asset.mileage_1} Km/L (checking...)
                                            </Typography>
                                    }
                                    <Typography variant="body2" color={colors.text.primary} sx={{ marginRight: '5px', marginLeft: '5px' }}>
                                        <b>Range: </b>  {range.toFixed(0)} Km <span style={{ color: colors.primary.shade11, cursor: 'pointer', textDecoration: 'underline' }} onClick={() => setRangeClarificationOpen(true)}><i>(estimated)</i></span>
                                    </Typography>
                                </Box>
                            </Box>
                            <Box display="flex" justifyContent="flex-start" alignItems="flex-end" flexDirection="row" sx={{ width: '100%', marginTop: '1rem' }}>
                                <Avatar sx={{ bgcolor: colors.yellow.shade13, width: 50, height: 50 }}>
                                    <Icon path={mdiCarCoolantLevel} size={1} />
                                </Avatar>
                                <Typography variant="h1" color={colors[proColor].main} sx={{ marginRight: '5px', fontSize: '2.5rem', marginLeft: '5px' }}>
                                    {parseFloat(asset.tank_1).toFixed(0)}L
                                </Typography>
                                <Typography variant="h5" color={colors[proColor].main} sx={{ marginBottom: '5px', fontWeight: 'bold' }}>
                                    /{asset.tankSize}
                                </Typography>
                                <Box
                                    sx={{
                                        flexGrow: 1,
                                        ml: 4,
                                        mb: 1,
                                        background: `linear-gradient(to right, ${colors[proColor].shade13} ${progress}%, ${colors[proColor].shade8} ${progress}%)`, height: 30,
                                        justifyContent: 'flex-end',
                                        borderRadius: '3px',
                                    }}
                                >
                                    <Typography variant="h5" color={colors.text.secondary} sx={{ marginLeft: '8px', marginTop: '8px' }}><b>{progress.toFixed(0)}%</b></Typography>
                                </Box>
                            </Box>
                        </CardContent>
                        <CardActions>
                            <Button onClick={showReports} variant="text" color="primary">Trip Report</Button>
                            <Button onClick={actionSummary} variant="text">
                                <span style={{ color: colors.freenzomp.shade11 }}>
                                    <b>Action Summary</b>
                                </span>
                            </Button>
                        </CardActions>
                    </Card>
                    {
                        asset.lastEvent &&
                        <FillCard lastEvent={asset.lastEvent} asset={asset} pumpname={pumpname} pumpAddress={pumpAddress} />
                    }
                </Box>
                <Dialog open={mileageClarificationOpen} onClose={handleMileageClarificationClose}>
                    <DialogTitle>Mileage Issue</DialogTitle>
                    <DialogContent>
                        <Typography paragraph>
                            Mileage for this vehicle cannot be properly calculated due data issues. Please check again later. <br />Mileage should be proper after the vehicle has run 100 Km or more. If the issue persists, please contact Customer Support.
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleMileageClarificationClose}>Close</Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={rangeClarificationOpen} onClose={handleRangeClarificationClose}>
                    <DialogTitle>Range Estimate</DialogTitle>
                    <DialogContent>
                        <Typography paragraph>
                            Range is the number of kilometers the vehicle can run on the current fuel level. <br />This is not exact and may vary based on driving conditions. Please refuel when the range becomes very low.
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleRangeClarificationClose}>Close</Button>
                    </DialogActions>
                </Dialog>
            </Box>
        );
    }
};

export default FuelDetails;