import { createSlice } from '@reduxjs/toolkit';
import { loginUser, searchUsername, searchUserbyAsset, getUserAccess } from './userApi';

const userSlice = createSlice({
    name: 'user',
    initialState: {
        username: '',
        userid: '',
        token: '',
        refreshToken: '',
        sockHost: '',
        isError: false,
        isSuccess: false,
        isFetching: false,
        needFirstLoad: false,
        isAdmin: false,
        isSubAccount: false,
        isUser: false,
        googleMapKey: '',
        googleKeyError: false,
        googleMapKeySource: '',
        googleMapdayConsumed: 0,
        googleMapmonthConsumed: 0,
        googleMapdayLimit: 0,
        googleMapmonthLimit: 0,
        hasGoogleAccess: 0,
        priviledge: null,
        parent_user: null,
        reports: [],
        error: null,
    },
    reducers: {
        clearState: (state) => {
            state.isError = false;
            state.isSuccess = false;
            state.isFetching = false;
            return state;
        },
        logout: (state) => {
            state.username = '';
            state.userid = '';
            state.token = '';
            state.refreshToken = '';
            state.sockHost = '';
            state.isError = false;
            state.isSuccess = false;
            state.isFetching = false;
            return state;
        },
        setNeedFirstLoad: (state, { payload }) => {
            state.needFirstLoad = payload;
            return state;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(loginUser.fulfilled, (state, { payload }) => {
            if (payload.error) {
                state.isError = true;
                state.error = payload.error;
            } else if (payload.token && payload.hosts) {
                state.isError = false;
                state.error = null;
                state.username = payload.username;
                state.userid = payload.userid;
                state.parentid = payload.parent_user;
                state.token = payload.token;
                state.refreshToken = payload.refreshToken;
                state.sockHost = `${payload.hosts.sockHost}?token=${payload.token}`;
                state.isAdmin = payload.admin;
                state.isSubAccount = payload.priviledge === 'subuser' ? true : false;
                state.isUser = payload.priviledge === 'user' ? true : false;
                state.googleMapKey = payload.googleMapKey;
                state.googleKeyError = payload.keyError;
                state.googleMapKeySource = payload.googleMapKeySource;
                state.googleMapdayConsumed = payload.dayConsumed;
                state.googleMapmonthConsumed = payload.monthConsumed;
                state.googleMapdayLimit = payload.dayLimit;
                state.googleMapmonthLimit = payload.monthLimit;
                state.hasGoogleAccess = payload.hasGoogleAccess;
                state.isError = false;
                //console.log("LOGIN SUCCESS");
            }
        });
    }
});

export const { clearState, logout, setNeedFirstLoad } = userSlice.actions;
export { loginUser, searchUsername, searchUserbyAsset, getUserAccess };


export const userSelector = (state) => state.user;
export const useridSelector = state => state.user.userid;
export const sockHostSelector = state => state.user.sockHost;
export const usernameSelector = state => state.user.username;
export const errorSelector = state => state.user.error;


export default userSlice.reducer;