import React, { useEffect } from 'react';
import moment from 'moment';
import { theme } from '../theme';
import { setDetailView, getMileage } from '../redux/assets/assetsSlice';
import { useDispatch } from 'react-redux';
import {
    Box,
    Card,
    CardContent,
    Typography,
    Button, IconButton, Avatar
} from '@mui/material';

import { useNavigate } from 'react-router-dom';
import LinearProgress from '@mui/material/LinearProgress';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

//import { getQuickLocation } from '../utils/olaApi';
import {
    getPeriodRange
} from '../utils/datePeriod';

import Icon from '@mdi/react';
import { mdiCarCoolantLevel } from '@mdi/js';

const DashboardWidgets = ({ asset }) => {
    const colors = theme.palette;
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const parsedTankSize = parseFloat(asset.tankSize);
    const parsedTank_1 = parseFloat(asset.tank_1);
    const progress = (parsedTank_1 / parsedTankSize) * 100;
    const proColor = progress > 20 ? 'primary' : 'redfire';
    const imei = asset.imei;
    const mileage_overall = asset && asset.action && asset.action.yesterdayTillNow && asset.action.yesterdayTillNow.mileage;

    const handleListItemClick = (imei) => {
        dispatch(setDetailView(imei));
        if (asset) {
            navigate('/fuel-details');
        }
    };
    /* const [locationAddress, setLocationAddress] = useState('');
    useEffect(() => {
        if (asset && asset.lat && asset.lng) {
            getQuickLocation(asset.lastEvent.lat, asset.lastEvent.lng).then((res) => {
                //console.log("OLA RGC", res);
                if (res) {
                    setLocationAddress(res);
                } else {
                    console.log("ONF");    
                }
            });
        }
    }, [asset]); */

    useEffect(() => {
        const period = 'yesterdayTillNow';
        const periodName = 'Yesterday Till Now';
        const { timeFrom, timeTo } = getPeriodRange(period);
        if (mileage_overall === 'NA') return;
        if (!mileage_overall) {
        //console.log("Mileage not found, fetching");
            dispatch(getMileage({ imei, period, periodName, timeFrom, timeTo }));
        } else {
            //console.log("Mileage available now", mileage_overall);
        }

    }, [imei, mileage_overall, dispatch])


    const assetStatus = (asset) => {
        if (parseInt(asset.acc) === 1) {
            if (parseInt(asset.speed) > 4) {
                return {
                    text: 'Running',
                    color: colors.freenzomp.main,
                    icon: null, //{/* <ArrowCircleRightIcon sx={{ borderRadius: '50%', color: colors.freenzomp.main }} /> */},
                    textVariant: 'h5'
                };
            }
            return {
                text: 'Idle',
                color: colors.secondary.shade13,
                icon: <PauseCircleOutlineIcon sx={{ marginRight: '0px', color: colors.secondary.shade13 }} />,
                textVariant: 'h4'
            };
        } else {
            return {
                text: 'Parked',
                color: colors.yellow.shade13,
                icon: null,
                textVariant: 'h4'
            };
        }
    }

    const lastFillingDisplay = () => {
        if (asset.lastEvent) {
            return (
                <Box sx={{ backgroundColor: colors.gainsboro.shade4, padding: 1, borderRadius: '5px' }}>
                    <Typography variant="subtitle2" color={colors.primary.shade12}>
                        <i>Last Filling:</i> <b>{asset.lastEvent.fuel_event_volume}L</b> at {asset.lastEvent.pumpName} <b>{moment(asset.lastEvent.eventTime).add(330, 'minutes').fromNow()}</b>
                    </Typography>
                </Box>
            );
        } else {
            return null;
        }
    }

    const renderWidget = (color) => (
        <Card sx={{ backgroundColor: colors.gainsboro.shade1, color: colors[color].main, mb: -4, padding: 1 }}>
            <CardContent>
                <Typography variant="h4" color={colors.text.lighter}><b>{asset.name}</b></Typography><Typography variant="subtitle2" color={colors.text.lighter} sx={{ fontSize: '0.6rem' }}><i>Updated {moment(asset.dt_server).fromNow()}</i></Typography>
                <Box display="flex" justifyContent="space-between" alignItems="center" flexDirection="row">
                    <Box sx={{ width: '65%' }}>
                        <br />
                        <Box
                            sx={{ cursor: 'pointer' }}
                            onClick={() => handleListItemClick(asset.imei)}
                            display="flex" justifyContent="flex-start" alignItems="flex-end" flexDirection="row"
                        >
                            <Avatar sx={{ bgcolor: colors[proColor].shade3 }}>
                                <Icon path={mdiCarCoolantLevel} size={1} color={colors[proColor].main} />
                            </Avatar>

                            <Typography variant="h1" color={colors[proColor].main} sx={{ marginRight: '5px', fontSize: '2.25rem' }}>
                                {parseFloat(asset.tank_1).toFixed(0)}L
                            </Typography>
                            <Typography variant="h5" color={colors.grey.main} sx={{ marginBottom: '5px', fontWeight: 'bold' }}>
                                /{asset.tankSize}
                            </Typography>
                        </Box>
                        <Box sx={{ width: '85%', marginTop: '1rem', }}>
                            <LinearProgress variant="determinate" value={progress} size="lg" thickness={72} color={proColor} />
                        </Box>
                    </Box>
                    <Box sx={{ width: '35%' }} display="flex" flexDirection='column' justifyContent="space-between" alignItems="flex-end">
                        <Box sx={{ mb: '1rem' }}>
                            <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
                                <IconButton size='small' >
                                    {assetStatus(asset).icon}
                                </IconButton>
                                <Typography variant={assetStatus(asset).textVariant} color={assetStatus(asset).color}>{assetStatus(asset).text.toUpperCase()}</Typography>
                            </Box>
                        </Box>
                        {
                            asset.action.yesterdayTillNow.mileage ?
                                asset.action.yesterdayTillNow.mileage === 'NA' ?
                                    <Box sx={{ mb: '1rem', textAlign: 'right' }}>
                                        <Typography variant="body2" color={colors.text.lighter}>{asset.mileage_1} Km/L (check later)</Typography>
                                    </Box>
                                    :
                                    <Box sx={{ mb: '1rem', textAlign: 'right' }}>
                                        <Typography variant="body2" color={colors.secondary.main}>{asset.action.yesterdayTillNow.mileage} Km/L</Typography>
                                    </Box>
                                :
                                <Box sx={{ mb: '1rem', textAlign: 'right' }}>
                                    <Typography variant="body2" color={colors.text.lighter}>{asset.mileage_1} Km/L (checking...)</Typography>
                                </Box>


                        }
                        {/* <Typography variant="subtitle2" color={colors.text.lighter} sx={{ fontSize: '0.6rem' }}><i>Updated {moment(asset.dt_server).fromNow()}</i></Typography> */}
                        <Box sx={{ marginTop: '0rem' }}>
                            <Button
                                variant="outlined"
                                size="medium"
                                fullWidth
                                onClick={() => handleListItemClick(asset.imei)}
                                sx={{ color: colors.primary.shade12 }}
                                endIcon={<ArrowForwardIcon />}
                            >
                                <span style={{ fontSize: '0.8em', fontWeight: 'bold', color: colors.primary.shade13 }}>More</span>
                            </Button>
                        </Box>
                    </Box>
                </Box>
                <br />
                {/* {locationAddress &&
                    <Box sx={{ backgroundColor: colors.grey.shade2, padding: 1, borderRadius: '5px', mb: 1 }}>
                        <Typography variant="body2" color={colors.freenzomp.shade14} sx={{ fontSize: '0.8rem' }}><b>Location:</b> {locationAddress}</Typography>
                    </Box>} */}
                {lastFillingDisplay()}
            </CardContent>
        </Card>
    );

    if (!asset) return null;
    return (
        <Box sx={{ flexGrow: 1, marginTop: 4, padding: 1 }}>
            <Box>
                {renderWidget('primary')}
            </Box>
        </Box>
    );
};

export default DashboardWidgets;