import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiClient } from '../../utils/axiosAdvanced';
import { toast } from "react-toastify";


export const getReportList = createAsyncThunk(
    'reports/getReportList',
    async (_, thunkAPI) => {
        try {
            //console.log("GEt report lust");
            const apiData = await apiClient.get('/get_reportlist');
            //console.log("Get list", apiData.data);
            //toast.success("Report downloaded");
            return apiData.data.reports;
        } catch (error) {
            //console.log("Get track list error", error);
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const downloadReport = createAsyncThunk(
    'reports/downloadReport',
    async (reportID, thunkAPI) => {
        //console.log("Download report data", reportID)
        try {
            const apiData = await apiClient.post('/get_reportdata', { reportID });
            return { reportID, reportData: JSON.parse(apiData.data.reportData) };
        } catch (error) {
            //console.log("Get reportdata error", error);
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const deleteReport = createAsyncThunk(
    'reports/deleteReport',
    async (reportID, thunkAPI) => {
        try {
            await apiClient.post('/delete_report', { reportID });
            //console.log("delete trackData", apiData.data);
            toast.success("Report Deleted");
            return { reportID };
        } catch (error) {
            //console.log("delete track error", error);
            return thunkAPI.rejectWithValue(error);
        }
    }
);


export const getPdf = createAsyncThunk(
    'reports/getPdf',
    async (reportID, thunkAPI) => {
        try {
            const apiData = await apiClient.post('download_pdf', { reportID, mapNeeded: true }, { responseType: 'blob' });
            try {
                const fileName = apiData.headers["content-disposition"].split("filename=")[1];
                const url = window.URL.createObjectURL(new Blob([apiData.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
            } catch (error) {
                toast.success("PDF download being prepared", { autoClose: 15000 });
            }
            return true;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const emailPdf = createAsyncThunk(
    'reports/emailPdf',
    async ({ reportID, emails, type, mapNeeded }, thunkAPI) => {
        try {
            await apiClient.post('email_pdf', { reportID, emails, type, mapNeeded }, { responseType: 'blob' });
            toast.success("Email request received", { autoClose: 8000 });
            return true;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const buildReportOne1 = createAsyncThunk(
    'reports/buildReportOne',
    async ({ assetIds, assets, reportType, minStop, minDrive,
        minActive, hold,
        speedLimit, timeFrom, timeTo, mapNeeded,
        event_desc, crawl, minTemp, maxTemp, interval,
        emailPDF, emails
    }, thunkAPI) => {
        try {
            let respArray = [];
            let index = 0;
            for (const assetId of assetIds) {
                const reqData = {
                    assetId, assetName: assets[index], minStop, minDrive,
                    minActive, hold, minTemp, maxTemp, interval,
                    speedLimit, timeFrom, timeTo, mapNeeded,
                    event_desc, crawl,
                    emailPDF, emails
                };
                const apiData = await apiClient.post(`/buildreport_${reportType.value}`, reqData);
                const { result, submitted, initialSummary } = apiData.data;
                if (submitted) {
                    respArray.push(initialSummary);
                } else {
                    if (result) {
                        toast.error(`${result} for ${reqData.assetName}`);
                    } else {
                        toast.error(`Related Sensor or Data not found for ${reqData.assetName}`);
                    }

                }
                index++;
            }
            const assetNames = assetIds.length === 1 ? assets[0] : `${assets.length} Vehicles`;
            const message = `Building ${reportType.name} Report for ${assetNames}`;
            toast.success(message);
            return respArray;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
)

export const buildReportOne = createAsyncThunk(
    'reports/buildReportOne',
    async ({ assetId, asset, reportType, timeFrom, timeTo,
        event_desc
    }, thunkAPI) => {
        try {
            let respArray = [];
            const reqData = {
                assetId, assetName: asset, timeFrom, timeTo, event_desc,
                crawl: true,
                minStop: 5, minDrive: 5,
                minActive: 5, hold: 5, minTemp: 5, maxTemp: 5, interval: 5,
                speedLimit: 5, mapNeeded: false,
                emailPDF: false, emails: []
            };
            const apiData = await apiClient.post(`/buildreport_${reportType}`, reqData);
            const { result, submitted, initialSummary } = apiData.data;
            if (submitted) {
                respArray.push(initialSummary);
            } else {
                if (result) {
                    toast.error(`${result} for ${reqData.assetName}`);
                } else {
                    toast.error(`Related Sensor or Data not found for ${reqData.assetName}`);
                }

            }
            return respArray;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
)