import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

import { theme } from '../theme';
import {
    Box,
    AppBar, Toolbar, Typography,
    Card, CardHeader, CardContent, CardActions,
    CircularProgress,
    Divider,
} from '@mui/material';
import { TextField, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import {
    reportIDsSelector, reportDataSelector, reportDetailViewSelector,
    buildReportOne, setReportDetailView, deleteReport, getReportList,
    downloadReport,
} from '../redux/datacenter/datacenterSlice';

import { detailViewSelector, rackSelector } from '../redux/assets/assetsSlice';

const Reports = () => {

    const colors = theme.palette;

    const dispatch = useDispatch();
    const reportIDs = useSelector(reportIDsSelector);
    const reportData = useSelector(reportDataSelector);
    const reportDetailView = useSelector(reportDetailViewSelector);

    const rack = useSelector(rackSelector);
    const detailView = useSelector(detailViewSelector);
    const asset = rack[detailView];

    const [reportList, setReportList] = useState([]);
    useEffect(() => {
        setReportList(reportIDs.filter(reportID => reportData[reportID]?.reqBody?.imei === asset?.imei));
    }, [reportIDs, reportData, asset])


    useEffect(() => {
        dispatch(getReportList());
        const interval = setInterval(() => {
            dispatch(getReportList());
        }, 60000);

        return () => clearInterval(interval);
    }, [dispatch]);

    const navigate = useNavigate();
    useEffect(() => {
        if (!asset) {
            navigate('/fuel-details');
        }
    }, [navigate, asset]);

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [dateError, setDateError] = useState({ startDate: '', endDate: '' });

    /*
    const [startDate, setStartDate] = useState(moment().subtract(1, 'day').format('YYYY-MM-DDTHH:mm'));
    const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DDTHH:mm'));
    const handleStartDateChange = (e) => {
        //console.log("SDate", e.target.value);
        setStartDate(e.target.value);
    };

    const handleEndDateChange = (e) => {
        setEndDate(e.target.value);
    };
    */
    const handleStartDateChange = (e) => {
        let newStartDate = e.target.value;
        const newEndDate = endDate;

        if (moment(newStartDate).isAfter(moment().subtract(3, 'hours'))) {
            setDateError({ startDate: 'Start date cannot be later than current time.', endDate: '' });
            newStartDate = moment().subtract(3, 'hours').format('YYYY-MM-DDTHH:mm');
        }

        if (newEndDate) {
            if (moment(newStartDate).isAfter(moment(newEndDate).subtract(3, 'hours'))) {
                setDateError({ startDate: 'Start date must be at least 3 hours before end date.', endDate: '' });
                newStartDate = moment(newEndDate).subtract(3, 'hours').format('YYYY-MM-DDTHH:mm');
            }

            if (moment(newEndDate).diff(moment(newStartDate), 'days') > 32) {
                setDateError({ startDate: 'Total duration between start date and end date should not be more than 32 days.', endDate: '' });
                newStartDate = moment(newEndDate).subtract(32, 'days').format('YYYY-MM-DDTHH:mm');
            }
        }
        setStartDate(newStartDate);
    };

    const handleEndDateChange = (e) => {
        let newEndDate = e.target.value;
        const newStartDate = startDate;

        if (moment(newEndDate).isAfter(moment())) {
            setDateError({ endDate: 'End date cannot be later than current time.', startDate: '' });
            newEndDate = moment().format('YYYY-MM-DDTHH:mm');
        }

        if (newStartDate) {
            if (moment(newEndDate).diff(moment(newStartDate), 'days') > 32) {
                setDateError({ endDate: 'Total duration between start date and end date should not be more than 32 days.', startDate: '' });
                newEndDate = moment(newStartDate).add(32, 'days').format('YYYY-MM-DDTHH:mm');
            }
            if (moment(newStartDate).isAfter(moment(newEndDate).subtract(3, 'hours'))) {
                setDateError({ endDate: 'End date must be at least 3 hours after start date.', startDate: '' });
                newEndDate = moment(newStartDate).add(3, 'hours').format('YYYY-MM-DDTHH:mm');
            }
        }
        setEndDate(newEndDate);
    };

    const getReport = (e) => {
        e.preventDefault();
        const req = {
            assetId: asset.imei,
            asset: asset.name,
            reportType: 'fuelactivity',
            timeFrom: moment(startDate).format('YYYY-MM-DD HH:mm:ss'),
            timeTo: moment(endDate).format('YYYY-MM-DD HH:mm:ss'),
            event_desc: 'Fuel',
        };
        setDateError("");
        dispatch(buildReportOne(req));
        //now make api call
    };

    const deleteNoDataReport = (reportID) => {
        if (reportDetailView === reportID) {
            dispatch(setReportDetailView(null));
        }
        dispatch(deleteReport(reportID));
        dispatch(getReportList());
    }

    const showReport = (reportID) => {
        dispatch(downloadReport(reportID));
        dispatch(setReportDetailView(reportID));
        navigate('/fuel-activity-report');
    }

    if (!asset) {
        return null;
    } else {
        return (
            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh', width: '100%' }}>
                <AppBar position="fixed">
                    <Toolbar sx={{ backgroundColor: colors.primary.main, color: colors.text.secondary }}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
                                <Typography variant="h5" component="div" sx={{ flexGrow: 1 }}>Trip Reports</Typography>
                            </Box>
                            <Box>
                                <Typography variant="h5" component="div" sx={{ flexGrow: 1 }}>{asset.name}</Typography>
                            </Box>
                        </Box>
                    </Toolbar>
                </AppBar>
                <Box padding={1}>
                    <Box sx={{ height: '40px' }} />
                    <Card sx={{ backgroundColor: colors.grey.shade1, color: colors.primary.main, mb: 0 }}>
                        <CardHeader
                            title={<Typography variant="h4" sx={{ color: colors.primary.shade12 }}><b>Get Trip Report with Fuel</b></Typography>}
                        />
                        <CardContent>
                            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
                                <Typography variant="h4" sx={{ color: colors.secondary.shade11 }}>Trip Start</Typography>
                                <TextField
                                    type="datetime-local"
                                    name="startDate"
                                    value={startDate}
                                    onChange={handleStartDateChange}
                                    max={moment(endDate).subtract(6, 'hours').format('YYYY-MM-DDTHH:mm')}
                                    min={moment(endDate).subtract(32, 'days').format('YYYY-MM-DDTHH:mm')}
                                />
                            </Box>
                            {dateError.startDate && <Typography variant="body2" sx={{ color: colors.redfire.main }}>{dateError.startDate}</Typography>}
                            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', marginTop: 2 }}>
                                <Typography variant="h4" sx={{ color: colors.secondary.shade11 }}>Trip End</Typography>
                                <TextField

                                    type="datetime-local"
                                    name="endDate"
                                    value={endDate}
                                    onChange={handleEndDateChange}
                                    max={moment().format('YYYY-MM-DDTHH:mm')}
                                    min={moment(startDate).add(6, 'hours').format('YYYY-MM-DDTHH:mm')}
                                />
                            </Box>
                            {dateError.endDate && <Typography variant="body2" sx={{ color: colors.redfire.main }}>{dateError.endDate}</Typography>}
                        </CardContent>
                        <CardActions>
                            <Button variant="contained" color="primary" onClick={getReport}>Get Report</Button>
                        </CardActions>
                    </Card>
                </Box>
                {reportList && reportList.length > 0 &&
                    <Box sx={{ padding: 1 }}>
                        <Card sx={{ backgroundColor: 'white', color: colors.primary.main, mb: 0 }}>
                            <CardHeader
                                title={<Typography variant="h4" sx={{ color: colors.primary.shade12 }}><b>Report List</b></Typography>}
                            />
                            <CardContent>
                                {
                                    reportData && reportList.map((reportID, index) => {
                                        if (!reportData[reportID]) return null;
                                        if (reportData[reportID].reqBody.imei !== asset.imei) return null;
                                        return (
                                            <Box
                                                display='flex'
                                                flexDirection='row'
                                                justifyContent='space-between'
                                                alignItems='center'
                                                sx={{ backgroundColor: colors.grey.shade1, marginBottom: 1, borderRadius: '5px', padding: 1 }}
                                                key={index}
                                            >
                                                <Box
                                                    sx={{
                                                        width: '65%',
                                                        /* border: '1px solid grey',*/
                                                        borderRadius: '5px',
                                                        backgroundColor: colors.grey.shade2,
                                                    }}
                                                    display={'flex'}
                                                    flexDirection={'column'}
                                                    padding={1}
                                                >
                                                    <Typography variant="body2" sx={{ color: colors.secondary.main }}>
                                                        <b>Trip Start: </b>
                                                    </Typography>
                                                    <Typography variant="body2" sx={{ color: colors.text.primary }}>
                                                        {moment(reportData[reportID].reqBody.timeFrom).format("D-MMM YYYY h:mm A")}
                                                    </Typography>
                                                    <Divider />
                                                    <Typography variant="body2" sx={{ color: colors.secondary.main }}>
                                                        <b>Trip End: </b>
                                                    </Typography>
                                                    <Typography variant="body2" sx={{ color: colors.text.primary }}>
                                                        {moment(reportData[reportID].reqBody.timeTo).format("D-MMM YYYY h:mm A")}
                                                    </Typography>
                                                </Box>
                                                <Box sx={{ width: '35%' }} display={'flex'} flexDirection={'column'}>
                                                    {
                                                        reportData[reportID].status === 'submitted' ?
                                                            <Button
                                                                startIcon={
                                                                    <CircularProgress color="secondary" size={20} />
                                                                }
                                                                onClick={() => { }}
                                                            //onClick={deleteTrackCallback}
                                                            >
                                                                <b><i><span style={{ color: 'red' }}>Building</span></i></b>
                                                            </Button>
                                                            :
                                                            reportData[reportID].status === 'nodata' ?
                                                                <Button
                                                                    startIcon={
                                                                        <DeleteIcon color={colors.redfire.main} />
                                                                    }
                                                                    onClick={() => deleteNoDataReport(reportID)}
                                                                >
                                                                    <b><i><span style={{ color: 'red' }}>No Data</span></i></b>
                                                                </Button>
                                                                :
                                                                <Button
                                                                    variant="text"
                                                                    color="primary"
                                                                    onClick={() => showReport(reportID)}
                                                                >
                                                                    View
                                                                </Button>
                                                    }
                                                </Box>
                                            </Box>
                                        );
                                    })
                                }

                            </CardContent>
                        </Card>

                    </Box>
                }
            </Box >
        );
    }


};

export default Reports;