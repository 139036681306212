import { createTheme } from "@mui/material/styles";
import { lighten, darken } from "@mui/system";

/* const baseColors = {
    primary: "#48ACF0",
    secondary: "#BC4BBE",
    freenzomp: "#31D357",
    redfire: "#E01600",
    yellow: "#E4CC37",//#CAAF02 "#f1d302" #FBFF12 #E4CC37
    grey: "#4D5359", // Base grey color
}; */

/* *
 * const baseColors = {
    primary: "#1982c4",
    secondary: "#6a4c93",
    freenzomp: "#5aaa95",
    redfire: "#c1292e",
    yellow: "#E4CC37",//#CAAF02 "#f1d302" #FBFF12 #E4CC37
    grey: "#9e9e9e", // Base grey color
};


const baseColors_pulli = {
    primary: "#007FFF",
    secondary: "#6D326D",
    freenzomp: "#25A18E",
    redfire: "#F93943",
    yellow: "#EEE1B3",//#CAAF02 "#f1d302" #FBFF12 #E4CC37 #FFA500
    grey: "#9e9e9e", // Base grey color
}; // pulli approved colors

const baseColorw_alt1 = {
    primary: "#3B64CA",
    secondary: "#8A39AC",
    freenzomp: "#59a136",
    redfire: "#bf404f",
    yellow: "#cbb862",//#CAAF02 "#f1d302" #FBFF12 #E4CC37
    grey: "#9e9e9e", // Base grey color
};

//3E43BB,613091,2B822D,99334C,B88B3D
const baseColorw_alt2 = {
    primary: "#3E43BB",
    secondary: "#613091",
    freenzomp: "#2B822D",
    redfire: "#99334C",
    yellow: "#FFA500",//#CAAF02 "#f1d302" #FBFF12 #E4CC37
    grey: "#9e9e9e", // Base grey color
};

//pulli colors with yellow changed to orange
const baseColorw_alt3 = {
    primary: "#007FFF",
    secondary: "#6D326D", //#937ADD
    freenzomp: "#00996b",
    redfire: "#F93943",
    yellow: "#FDAC53",//#CAAF02 "#f1d302" #FBFF12 #E4CC37 #FFA500
    grey: "#9e9e9e", // Base grey color
};
 * 
 */
const baseColorw_alt4 = {
    primary: "#007FFF",
    secondary: "#937ADD", //#937ADD
    freenzomp: "#00996b",
    redfire: "#F93943",
    yellow: "#FDAC53",//#CAAF02 "#f1d302" #FBFF12 #E4CC37 #FFA500
    grey: "#9e9e9e", // Base grey color
    gainsboro: '#DCDCDC',
};

const baseColors = baseColorw_alt4;

const textColor = "#4D5359";
// const textColor = "#343a40";

const generateShades = (color) => {
    const shades = {};
    for (let i = 1; i <= 10; i++) {
        shades[`shade${10 - i}`] = lighten(color, i * 0.1);
    }
    shades.main = color;
    for (let i = 1; i <= 10; i++) {
        shades[`shade${10 + i}`] = darken(color, i * 0.1);
    }
    return shades;
};

const theme = createTheme({
    palette: {
        primary: generateShades(baseColors.primary),
        secondary: generateShades(baseColors.secondary),
        freenzomp: generateShades(baseColors.freenzomp),
        redfire: generateShades(baseColors.redfire),
        yellow: generateShades(baseColors.yellow),
        grey: generateShades(baseColors.grey),
        gainsboro: generateShades(baseColors.gainsboro),
        text: {
            primary: textColor,
            secondary: lighten(textColor, 0.9),
            lighter: lighten(textColor, 0.2),
            fader: lighten(textColor, 0.4),
        },
        background: {
            default: "#ffffff",
            paper: "#f5f5f5",
        },
    },
    typography: {
        fontFamily: "'Lato','Nexa','open-sans', 'sans-serif'", // Add 'lato' as the primary font and 'open-sans' as the fallback font
        h1: {
            fontSize: "2rem",
            fontWeight: 500,
            fontFamily: "'Lato','Nexa','open-sans', 'sans-serif'",
        },
        h2: {
            fontSize: "1.75rem",
            fontWeight: 500,
            fontFamily: "'Lato','Nexa','open-sans', 'sans-serif'",
        },
        h3: {
            fontSize: "1.5rem",
            fontWeight: 500,
            fontFamily: "'Lato','Nexa','open-sans', 'sans-serif'",
        },
        h4: {
            fontSize: "1.25rem",
            fontWeight: 500,
            fontFamily: "'Lato','Nexa','open-sans', 'sans-serif'",
        },
        h5: {
            fontSize: "1rem",
            fontWeight: 500,
            fontFamily: "'Lato','Nexa','open-sans', 'sans-serif'",
        },
        subtitle1: {
            fontSize: "1rem",
            fontWeight: 400,
            fontFamily: "'Lato','Nexa','open-sans', 'sans-serif'",
        },
        subtitle2: {
            fontSize: "0.875rem",
            fontWeight: 400,
            fontFamily: "'Lato','Nexa','open-sans', 'sans-serif'",
        },
        body1: {
            fontSize: "1rem",
            fontFamily: "'Lato','Nexa','open-sans', 'sans-serif'",
        },
        body2: {
            fontSize: "0.875rem",
            fontFamily: "'Lato','Nexa','open-sans', 'sans-serif'",
        },
    },
    components: {
        MuiIconButton: {
            styleOverrides: {
                sizeMedium: {
                    color: 'black'
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    color: 'black'
                }
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: 'black'
                }
            }
        }
    },
});

export { theme };