import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';
import { configApiClient, apiClient } from '../../utils/axiosAdvanced';


export const loginUser = createAsyncThunk(
    'user/loginUser',
    async ({ ilogusername, ilogpassword }, thunkAPI) => {
        try {
            //console.log(appStage())
            const response = await axios.post(`${appStage().url}/ilog_login`, { ilogusername, ilogpassword });
            let data = response.data;
            if (appStage().appType === 'development') {
                //console.log('login response', appStage().appType, response.data.result);
            }

            if (response.status === 200) {
                if (data.error) {
                    thunkAPI.rejectWithValue(data.error);
                } else if (data.token && data.hosts) {
                    configApiClient(data.token, data.hosts);
                } else {
                    thunkAPI.rejectWithValue(data);
                }
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (error) {
            thunkAPI.rejectWithValue(error.response.data);

        }
    }
);

export const searchUsername = createAsyncThunk(
    'user/searchUsername',
    async ({ searchUser }, thunkAPI) => {
        try {
            const apiData = await apiClient.post('/search_username', { searchUser });
            //console.log("USe ser api", apiData.data);
            let res = apiData.data.users;
            //console.log("res us", res);
            res.sort(function (a, b) {
                return parseInt(a.userid) - parseInt(b.userid);
            })
            return res;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
)

export const searchUserbyAsset = createAsyncThunk(
    'user/searchUserbyAsset',
    async ({ searchAsset }, thunkAPI) => {
        try {
            const apiData = await apiClient.post('/search_user_by_asset', { searchAsset });
            let res = apiData.data.users;
            //console.log("res ass", res);
            res.sort(function (a, b) {
                return parseInt(a.userid) - parseInt(b.userid);
            })
            return res;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
)

export const getUserAccess = createAsyncThunk(
    'user/getUserAccess',
    async ({ requestedUser }, thunkAPI) => {
        try {
            const apiData = await apiClient.post('/get_useraccess', { requestedUser });
            const data = apiData.data;

            if (apiData.status === 200) {
                if (data.error) {
                    thunkAPI.rejectWithValue(data.error);
                } else if (data.token && data.hosts) {
                    configApiClient(data.token, data.hosts);
                } else {
                    thunkAPI.rejectWithValue(data);
                }
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
)

function appStage() {
    const isProduction = window.location.href.indexOf("fuel.ilogistek.com") !== -1;
    const isBeta = window.location.href.indexOf("https://main.d14ophx8lu6uok.amplifyapp.com") !== -1;

    const ports = {
        production: 10001,
        beta: 10001,
        development: 10002,
    };

    let appType;
    if (isProduction) {
        appType = "production";
    } else if (isBeta) {
        appType = "beta";
    } else {
        appType = "development";
    }

    return { appType, url: `https://mobileauth.roadmatics.com:${ports[appType]}` }

}
