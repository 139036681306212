import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiClient } from '../../utils/axiosAdvanced';

export const assetHydrate = createAsyncThunk(
    'assets/assetHydrate',
    async () => {
        const apiData = await apiClient.get('/fuel_hydrate');
        //console.log("Asset List Hydrate", apiData.data);
        if (apiData.data.status === "error") {
            throw new Error(apiData.data.message);
        } else {
            const data = apiData.data.data;
            let assets = [];
            data.forEach((asset) => {
                //console.log("first asset", asset);
                const prep = {
                    imei: asset.imei,
                    name: asset.name,
                    dt_tracker: asset.dt_tracker,
                    lat: asset.lat,
                    lng: asset.lng,
                    tank_1: asset.tank_1,
                    mileage_1: asset.mileage_1,
                    acc: asset.acc,
                    speed: asset.speed,
                    odometer: asset.odometer,
                    alapwr: asset.alapwr,
                    tankParams: asset.tankParams,
                    //model, vehicleType, tankZize, tankContent, sensor, sensorSerial, sensorLastCalib
                    model: asset.tankParams.model,
                    vehicleType: asset.tankParams.vehicleType,
                    tankSize: asset.tankParams.tankSize,
                    tankContent: asset.tankParams.tankContent,
                    sensor: asset.tankParams.sensor,
                    sensorSerial: asset.tankParams.sensorSerial,
                    sensorLastCalib: asset.tankParams.sensorLastCalib,
                    dt_server: asset.dt_server,
                    lastEvent: asset.lastEvent,
                };
                //console.log("first prep", prep);
                assets.push(prep);
            });
            return assets;
        }
    },
);

export const getMileage = createAsyncThunk(
    'assets/getMileage',
    async ({ imei, timeFrom, timeTo, periodName, period }, thunkAPI) => {
        try {
            const req = { timeFrom, timeTo, imei };
            const apiData = await apiClient.post('/fuel_integrated', req);
            const result = { actionSummary: { ...apiData.data }, periodName, period, imei };
            return result;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    },
);
