import { createSlice } from '@reduxjs/toolkit';
import {
    getReportList, downloadReport,
    deleteReport, getPdf, emailPdf, buildReportOne
} from './datacenterApi';

const reportTypes = ['fuelactivity', 'fuelfillntheft'];

const initialState = {
    reportIDs: [],
    reportData: {},
    reportDetailView: null,
    downloadingReportList: false,
    downloadingReportData: false,
};

const datacenterSlice = createSlice({
    name: 'datacenter',
    initialState,
    reducers: {
        setReportDetailView: (state, { payload }) => {
            if (payload !== null) {
                state.reportDetailView = payload;
            } else {
                state.reportDetailView = null;
            }
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getReportList.pending, (state) => {
            state.downloadingReportList = true;
            return state;
        });
        builder.addCase(getReportList.fulfilled, (state, { payload }) => {
            state.downloadingReportList = false;
            const { reportIDs, reportData } = reportListBuild(payload);
            state.reportIDs = reportIDs;
            state.reportData = reportData;
            //console.log("reportlist updated", reportIDs.length);
            return state;
        });

        builder.addCase(downloadReport.pending, (state) => {
            state.downloadingReportData = true;
            return state;
        });
        builder.addCase(downloadReport.fulfilled, (state, { payload }) => {
            state.downloadingReportData = false;
            state.reportData[payload.reportID].dataTable = true;
            state.reportData[payload.reportID] = {
                ...state.reportData[payload.reportID],
                ...payload.reportData
            };
            //console.log("Report Slice", state.reportData[payload.reportID]);
            return state;
        });

        builder.addCase(deleteReport.fulfilled, (state, { payload }) => {
            //console.log("delete opayload", payload);
            const { reportIDs, reportData } = deleteReportData(state.reportIDs, state.reportData, payload.reportID);
            state.reportIDs = reportIDs;
            state.reportData = reportData;
            return state;
        });

        builder.addCase(emailPdf.fulfilled, (state, action) => {
            // Email PDF
            console.log('Email sent:', action.payload);
        });

        builder.addCase(getPdf.fulfilled, (state, action) => {
            // Download PDF
            const url = window.URL.createObjectURL(new Blob([action.payload]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'report.pdf');
            document.body.appendChild(link);
            link.click();
        });

        builder.addCase(buildReportOne.fulfilled, (state, { payload }) => {
            //console.log("Buildreport response recd", payload);
            payload.forEach(p => {
                saveAcknowledgement(p);
                const { reportIDs, reportData } = sockReportPusher(state.reportIDs, state.reportData, p);
                state.reportIDs = reportIDs;
                state.reportData = reportData;
            })
            return state;
        });
    },
});

export {
    getReportList, downloadReport,
    deleteReport, getPdf, emailPdf, buildReportOne
};

export const { setReportDetailView } = datacenterSlice.actions;

export default datacenterSlice.reducer;

export const reportIDsSelector = state => state.datacenter.reportIDs;
export const reportDataSelector = state => state.datacenter.reportData;
export const reportDetailViewSelector = state => state.datacenter.reportDetailView;
export const downloadingReportListSelector = state => state.datacenter.downloadingReportList;
export const downloadingReportDataSelector = state => state.datacenter.downloadingReportData;

function sockReportPusher(list, data, reportSummary) {
    let reportIDs = [...list];
    let reportData = { ...data };
    const { reportID, reportType } = reportSummary;
    if (reportType !== 'track') {
        if (reportIDs.indexOf(reportID) === -1) {
            reportIDs.push(reportID);
        }
        reportData = {
            ...reportData,
            [reportID]: {
                ...reportData[reportID],
                ...reportSummary
            },
        };
    }

    reportIDs = sortReportIDs(reportIDs, reportData);
    return { reportIDs, reportData };
}

function reportListBuild(reports) {
    let reportIDs = [];
    let reportData = {};
    //remove completed reports from localstore
    // console.log("Reports before cleanup", reports.length);
    reports.forEach(report => {
        const { reportID } = report;
        deleteAcknowledgement(reportID);
    });
    //console.log("Reports after cleanup", reports.length);
    //add pending reports from locastore
    reports = [...getAcknowledgements(), ...reports];
    //console.log("Reports after adding pending", reports.length);
    if (reports.length > 0) {
        reports.forEach(report => {
            const { reportID, reportType } = report;
            if (reportTypes.includes(reportType)) {
                if (reportIDs.indexOf(reportID) === -1) {
                    reportIDs.push(reportID);
                }
                reportData = {
                    ...reportData,
                    [reportID]: {
                        ...reportData[reportID],
                        ...report,
                    },
                };
            }
        })
    }
    reportIDs = sortReportIDs(reportIDs, reportData);
    //console.log("reportlist updated", reportIDs.length);
    return { reportIDs, reportData };
}

function sortReportIDs(list, content) {
    list.sort(function (a, b) {
        const keyA = content[`${a}`].reqTime;
        const keyB = content[`${b}`].reqTime;
        return keyB - keyA;
    });
    return list;
}

function deleteReportData(list, data, reportID) {
    let reportIDs = [...list];
    let reportData = { ...data };

    reportIDs = reportIDs.filter(a => a !== reportID);
    reportData[reportID] = null;
    return { reportIDs, reportData };
}

// Save the acknowledgement object
const saveAcknowledgement = (acknowledgement) => {
    const acknowledgements = JSON.parse(localStorage.getItem('acknowledgements')) || [];
    acknowledgements.push(acknowledgement);
    localStorage.setItem('saved acknowledgements', JSON.stringify(acknowledgements));
    return;
};

// Retrieve the acknowledgement objects
const getAcknowledgements = () => {
    return JSON.parse(localStorage.getItem('acknowledgements')) || [];
};

// Delete the specific acknowledgement object
const deleteAcknowledgement = (reportID) => {
    let acknowledgements = JSON.parse(localStorage.getItem('acknowledgements')) || [];
    acknowledgements = acknowledgements.filter(ack => ack.reportID !== reportID);
    localStorage.setItem('deleted acknowledgements', JSON.stringify(acknowledgements));
};
