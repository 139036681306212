import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    AppBar, Toolbar, IconButton, Typography, Box,
    Fab, Dialog, DialogTitle, DialogContent, TextField,
    DialogActions, Button, LinearProgress,
} from '@mui/material';


import LogoutIcon from '@mui/icons-material/Logout';
import SearchIcon from '@mui/icons-material/Search';
import { theme } from '../theme'; // Adjust the import path as needed
import {
    assetHydrate, rackSelector, assetListSelector,
    visibleAssetsListSelector, searchAsset, loadingSelector
} from '../redux/assets/assetsSlice';
import DashboardWidgets from './Dashboard';

function FleetDashboard() {

    const dispatch = useDispatch();
    const colors = theme.palette;
    //const username = useSelector(usernameSelector);
    const rack = useSelector(rackSelector);
    const updated = rack && rack.updated;
    const visibleAssetsList = useSelector(visibleAssetsListSelector);
    const assetList = useSelector(assetListSelector);
    const loading = useSelector(loadingSelector);

    const logout = () => {
        localStorage.removeItem('username');
        localStorage.removeItem('password');
        window.location.href = '/';
    }

    useEffect(() => {
        //console.log("Sending hydrate on load");
        if (updated) {
            const now = Date.now();
            const diff = now - updated;
            if (diff < 3 * 60 * 1000) {
                return;
            } else {
                dispatch(assetHydrate());
            }
        } else {
            dispatch(assetHydrate());
        }


        const interval = setInterval(() => {
            dispatch(assetHydrate());
        }, 3 * 60 * 1000);

        return () => {
            clearInterval(interval);
        };
    }, [dispatch, updated]);


    const renderAssetList = () => {
        return visibleAssetsList.map((imei) => <DashboardWidgets key={imei} asset={rack[imei]} />);
    };
    const [open, setOpen] = useState(false);
    const [searchValue, setSearchValue] = useState('');

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setSearchValue('');
        setOpen(false);
    };

    const handleShowAll = () => {
        dispatch(searchAsset(''));
        handleClose();
    };

    const handleSearch = () => {
        dispatch(searchAsset(searchValue));
        handleClose();
    };


    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh', width: '100%' }}>
            <AppBar position="fixed">
                <Toolbar sx={{ backgroundColor: colors.primary.shade13, color: colors.text.secondary }}>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>My Fleet</Typography>
                    <IconButton edge="end" color="inherit" aria-label="logout" onClick={logout}>
                        <LogoutIcon sx={{ color: colors.grey.shade1 }} />
                    </IconButton>
                </Toolbar>
            </AppBar>
            {loading &&
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', flexDirection: 'column' }}>
                    <Typography variant="h4" component="div">Loading...</Typography>
                    <br />
                    <LinearProgress color="secondary" sx={{ width: '70%' }} />
                </Box>
            }
            <Box sx={{ flexGrow: 1, overflow: 'auto', marginTop: '5px', marginBottom: '2px', padding: 1 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh', marginTop: '2vh' }}>
                    {renderAssetList()}
                </Box>
            </Box>

            {
                assetList.length !== visibleAssetsList.length ? <Fab
                    color="secondary"
                    aria-label="search"
                    sx={{
                        position: 'fixed',
                        bottom: '16px',
                        right: '16px',
                        color: colors.text.secondary,
                        backgroundColor: colors.secondary.shade14
                    }}
                    onClick={handleShowAll}
                >
                    <span style={{ fontSize: '0.7em', fontWeight: 'bold', color: colors.text.secondary }}>Show All</span>
                </Fab>

                    :
                    <Fab
                        aria-label="search"
                        sx={{
                            position: 'fixed',
                            bottom: '16px',
                            right: '16px',
                            color: colors.text.secondary,
                            backgroundColor: colors.primary.shade14
                        }}
                        onClick={handleOpen}
                    >
                        <SearchIcon />
                    </Fab>
            }
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Search Asset</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Vehicle Name"
                        type="text"
                        fullWidth
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleSearch} variant="contained" color="primary">
                        Search
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

export default FleetDashboard;
