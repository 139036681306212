import { configureStore } from '@reduxjs/toolkit';

import userReducer from './user/userSlice';
import assetsReducer from './assets/assetsSlice';
import datacenterSlice from './datacenter/datacenterSlice';


const store = configureStore({
    reducer: {
        user: userReducer,
        assets: assetsReducer,
        datacenter: datacenterSlice,
    },
});

export default store;